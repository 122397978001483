import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { controllerMiddleware } from '../middlewares';
import configs from '../../configs/config';
import * as reducers from '../reducers';
import { userRoleReducer } from '../reducers';

const rootReducers = combineReducers({
    token: reducers.tokenReducers,
    reports: reducers.reportsReducer,
    languages: reducers.languagesReducer,
    partners: reducers.partnersReducer,
    users: reducers.usersReducer,
    contestTypes: reducers.contestTypesReducer,
    maintenances: reducers.maintenancesReducer,
    isFinishedMaintenances: reducers.IsFinishedMaintenances,
    notification: reducers.notificationReducer,
    userRole: userRoleReducer,
    betTypes: reducers.betTypesReducer,
    tournaments: reducers.tournamentsReducer,
    userTournaments: reducers.userTournamentsReducer,
    freeBets: reducers.freeBetReducer,
});

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(controllerMiddleware(configs)),
  // other store enhancers if any
);

const store = createStore(rootReducers, enhancer);
export default store;

