import {connect} from 'react-redux';
import Profile from "bet-core-ui/Profile";
import React, {useMemo, useState} from 'react';
import useTranslation from "../../../../hooks/useTranslation";
import {cntrlLogOut} from "../../../../state-management/actions/tokenActions";
import {cntrlGetTranslations} from "../../../../state-management/actions/languagesActions";
import {languagesAccessor} from "../../../../state-management/accessors/languagesAccessor";

const UserProfile = ({
                     logout ,
                     resetPermissions ,
                     client,
                     languages : {data : languages},
                     getTranslations,
                     ...props
                 }) => {

    const [isOpenUserProfile, setIssOpenUserProfile] = useState(false);
    const translate = useTranslation();
    const [selectedLanguage , setLanguage] = useState(null);


    const languageList = useMemo(() => languages
        .map(item => ({
            ...item,
            checkMark: true,
            active : selectedLanguage === item.uid,
            title: item.name,
            onClick: () => {
                getTranslations(item.uid);
                setLanguage(item.uid);
            },
        })), [languages , selectedLanguage]);


    const renderUserAccountMenu = () => ([
        {
            title : translate('SETTINGS'),
            divider : {hasFullWidth : true},
        },
        {
            title : translate('RESET_PASSWORD'),
            divider : {hasFullWidth : true},

        },
        {
            title : `${translate('LANGUAGE')}`,
            divider : {hasFullWidth : true},
            children : languageList,
            border: 'bottom',
        },
        {
            title : translate('LOG_OUT'),
            divider : {hasFullWidth : true},
            onClick : () => {
                logout();
                // resetPermissions()

            }
        },
    ]);


    const avatarPropsConfig = {
        color: 'oneOf',
        size: 'small',
    };


    return(
        <>

            <Profile
                username={client.data.userName}
                showIcon={false}
                isOpen={isOpenUserProfile}
                toggleHandler={() => setIssOpenUserProfile(!isOpenUserProfile)}
                avatarProps={avatarPropsConfig}
                menuProps={{
                    data : renderUserAccountMenu()
                }}
            />

        </>
    )
};

const mapStateToProps = state => ({
    languages : languagesAccessor(state)
});
const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(cntrlLogOut()),
    getTranslations : languageId => dispatch(cntrlGetTranslations(languageId))
    // resetPermissions : () => dispatch(rxGetTokenPermissionDone([])),
});

export default connect(mapStateToProps , mapDispatchToProps)(UserProfile);


