import './style.css';
import React from 'react';
import UserProfile from './profile'
import {connect} from 'react-redux';
import{Link} from 'react-router-dom';
import { Icon, Time, Divider, Button} from 'bet-core-ui';
import {userAccessor} from "../../../state-management/accessors/userAccessor";


const Header = ({
                  isAuth,
                  client,
                  ...props}) => {

    return(
    <header className='header-wrapper'>
      <ul>
        <li>
          <Link to='/dashboard'>
              <Icon type='bc-icon-betconstruct' className={'header-icons'}/>
          </Link>
          {isAuth && <>
            <Divider type='vertical'/>
            <Button
              appearance='minimal'
              color='default'
              cornerRadius='round'
              flexibility='default'
              icon = 'bc-icon-plus-upload'
              itemsDirection='start'
              size='default'
            />
          </>}

        </li>
        {isAuth && <>
          <li className={'header-profile-content'}>
            <Divider type='vertical'/>
              <div className={'time-holder-div'}>
                  <Time/>
              </div>
            <UserProfile client={client}/>
          </li>

        </>}

      </ul>

    </header>
  );
};

const mapStateToProps = state => ({
  isAuth : state.token.isAuthReducer,
  client : userAccessor(state)
});

export default connect(mapStateToProps, null)(Header);
