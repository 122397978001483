import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class BetTypesOperation extends Operations {
    constructor(betTypesApiHandler) {
        super();
        this.betTypesApiHandler = betTypesApiHandler;
    }

    async getBetTypes(query) {
        const response = await this.betTypesApiHandler.getBetTypes(query);
        return responseErrorCheck(response)
    };

}

export default BetTypesOperation;
