import {combineReducers} from "redux";
import createAsyncReducer from "../../core/helpers/createAsyncReducer";
import {PARTNERS} from "../../core/constants/actionTypes";
import createReducer from "../../core/helpers/createReducer";

export const partnersReducer = combineReducers({
    partners : createAsyncReducer(PARTNERS.RX_GET_PARTNERS , {
        totalCount : 0,
        filteredItems : []
    }),

    allPartners : createAsyncReducer(PARTNERS.RX_GET_ALL_PARTNERS , {
        totalCount : 0,
        filteredItems : []
    }),

    partnersMainLimit : createAsyncReducer(PARTNERS.RX_GET_PARTNERS_MAIN_LIMIT , {
        totalCount : 0,
        filteredItems : []
    }),

    partnersBetTypeConfigs : createAsyncReducer(PARTNERS.RX_GET_PARTNERS_BET_TYPE_CONFIGS , {
        totalCount : 0,
        filteredItems : []
    }),

    // add Partner reducer
    addPartner:createAsyncReducer(PARTNERS.RX_ADD_PARTNERS,{}),

    //change PArtner Status
    partnerStatus:createAsyncReducer(PARTNERS.RX_EDIT_GET_PARTNERS_STATUS,{}),

    partnersBetTypeLimitConfigs : createAsyncReducer(PARTNERS.RX_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS , []),

    partnerContestType : createAsyncReducer(PARTNERS.RX_GET_PARTNERS_CONTEST_TYPE, []),

    partnerContestTypesConfig : createAsyncReducer(PARTNERS.RX_GET_PARTNERS_CONTEST_TYPES_CONFIG,[]),

    currencies : createAsyncReducer(PARTNERS.RX_GET_PARTNERS_CURRENCIES , []),

    partnerSelectedCurrencies: createAsyncReducer(PARTNERS.RX_GET_SELECTED_PARTNERS_CURRENCIES , []),

    partnerCurrency : createReducer({} , {
        [PARTNERS.RX_SET_PARTNER_CURRENCY] : (store , action ) => action.payload
    })
});
