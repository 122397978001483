import Controller from "../../core/classes/AppController";
import translationService from "../../services/services/TranslationService";
import {
    rxGetLanguagesDone,
    rxGetLanguagesPending,
    rxGetTranslationsDone,
    rxGetTranslationsPending
} from "../../state-management/actions/languagesActions";

class LanguagesController extends Controller{
    constructor(languagesOperation) {
        super();
        this.languagesOperation = languagesOperation;
    }

    getTranslationsByCode = async (store , action) => {
        store.dispatch(rxGetTranslationsPending());
        const response = await this.languagesOperation.getTranslationsByCode(action.payload);
        translationService.setState(response);
        store.dispatch(rxGetTranslationsDone(response))
    };

    getLanguages = async (store , action) => {
        store.dispatch(rxGetLanguagesPending());
        const response = await this.languagesOperation.getLanguages(action.payload);
        store.dispatch(rxGetLanguagesDone(response))
    }
}

export default LanguagesController;
