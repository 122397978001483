import Controller from "../../core/classes/AppController";
import {
    rxGetMaintenancesPending,
    rxGetMaintenancesDone,
    rxAddMaintenancesPending,
    rxAddMaintenancesDone,
    rxCancelMaintenancesPending,
    rxCancelMaintenancesDone,
    rxEditEndDateDone,
    rxEditEndDatePending
} from "../../state-management/actions/maintenancesActions";


class MaintenancesController extends Controller{
    constructor(maintenancesOperation){
        super();
        this.maintenancesOperation = maintenancesOperation;
    }

    getMaintenances = async (store) => {
        try {
            store.dispatch(rxGetMaintenancesPending());
            const response = await this.maintenancesOperation.getMaintenances();
            store.dispatch(rxGetMaintenancesDone(response));
        }catch (e) {
            return new Error(e);
        }
    };

    addMaintenances = async (store, action) => {
        store.dispatch(rxAddMaintenancesPending());
        const response = await this.maintenancesOperation.addMaintenances(action.payload);
        store.dispatch(rxAddMaintenancesDone(response));
        return response
    };

    cancelMaintenances = async (store, action) => {
        store.dispatch(rxCancelMaintenancesPending());
        const response = await this.maintenancesOperation.cancelMaintenances(action.payload);
        store.dispatch(rxCancelMaintenancesDone(response));
        return response
    };

    editEndDate = async (store, action) => {

        store.dispatch(rxEditEndDatePending());
        const response = await this.maintenancesOperation.editEndDate(action.payload);
        store.dispatch(rxEditEndDateDone(response));
        return response
    }
}

export default MaintenancesController