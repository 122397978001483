import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class UserTournamentsOperations extends Operations {
  constructor(userTournamentsApiHandler) {
    super();
    this.userTournamentsApiHandler = userTournamentsApiHandler;
  }

  async getUserTournamentsReport(query) {
    const response = await this.userTournamentsApiHandler.getUserTournamentsReport(query);
    return responseErrorCheck(response)
  };

  async getUserTournamentsRangeReport(query) {
    const response = await this.userTournamentsApiHandler.getUserTournamentsRangeReport(query);
    return responseErrorCheck(response)
  };
  
}

export default UserTournamentsOperations;
