import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class TournamentOperations extends Operations {
    constructor(tournamentApiHandler) {
        super();
        this.tournamentApiHandler = tournamentApiHandler;
    }

    async getTournaments(query) {
        const response = await this.tournamentApiHandler.getTournaments(query);
        return responseErrorCheck(response)
    };

    async getTournament(query) {
        const response = await this.tournamentApiHandler.getTournament(query);
        return responseErrorCheck(response)
    };

    async addTournament(action) {
        const response = await this.tournamentApiHandler.addTournament(action.payload);
        return responseErrorCheck(response);
    }

    async updateTournament(action) {
        const response = await this.tournamentApiHandler.updateTournament(action.payload);
        return responseErrorCheck(response);
    }

    async cancelTournament(action) {
        const response = await this.tournamentApiHandler.cancelTournament(action.payload);
        return responseErrorCheck(response);
    }

    async getTournamentPlayers(action) {
        const response = await this.tournamentApiHandler.getTournamentPlayers(action);
        return responseErrorCheck(response);
    }

    async getPartnersTournament(action) {
        const response = await this.tournamentApiHandler.getPartnersTournament(action);
        return responseErrorCheck(response);
    }

}

export default TournamentOperations;
