import Controller from "../../core/classes/AppController";
import {
    rxAddContestTypeDone,
    rxAddContestTypePending, rxDeletePartnersFromContestTypeDone, rxDeletePartnersFromContestTypePending,
    rxEditContestTypeDone,
    rxEditContestTypePending,
    rxGetContestTypesDone,
    rxGetContestTypesPending
} from "../../state-management/actions/contestTypes";

class ContestTypesController extends Controller{
    constructor(contestTypesOperation){
        super();
        this.contestTypesOperation = contestTypesOperation;
    }

    getContestTypes = async (store,action) =>{
        try {
            store.dispatch(rxGetContestTypesPending());
            const response = await this.contestTypesOperation.getContestTypes(action.payload);
            store.dispatch(rxGetContestTypesDone(response));
        }catch (e) {
            return new Error(e);
        }
    };

    addContestType = async (store,action) =>{
        store.dispatch(rxAddContestTypePending());
        const response = await this.contestTypesOperation.addContestType(action.payload);
        store.dispatch(rxAddContestTypeDone(response));
        return response
    };

    editContestType = async (store,action) =>{
        store.dispatch(rxEditContestTypePending());
        const response = await this.contestTypesOperation.editContestType(action.payload);
        store.dispatch(rxEditContestTypeDone(response));
        return response
    };

    deletePartnersFromContestType = async (store,action) =>{
        store.dispatch(rxDeletePartnersFromContestTypePending());
        const response = await this.contestTypesOperation.deletePartnersFromContestType(action.payload);
        store.dispatch(rxDeletePartnersFromContestTypeDone(response));
        return response
    }
}

export default ContestTypesController;
