import HiLoBackofficeApiHandler from "../HiLoBackofficeApiHandler";


const END_POINT = {
    prefix : 'Maintenances',
    start : 'Start',
    stop: 'Stop',
    changeEndDate : 'ChangeEndDate',

};
class MaintenancesApiHandler extends HiLoBackofficeApiHandler{

    getMaintenances(){
        return super.get()
    }
    addMaintenances(data){
        return super.post(END_POINT.stop , data);
    }
    cancelMaintenances(id){
        return super.put(`${id}/${END_POINT.start}`)
    }
    editEndDate({id,endDate}){
        return super.put(`${id}/${END_POINT.changeEndDate}?endDate=${endDate}`)
    }
}
const maintenancesApiHandler = new MaintenancesApiHandler(END_POINT.prefix);
export default maintenancesApiHandler;
