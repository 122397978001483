import Controller from "../../core/classes/AppController";
import {rxGetUserDone, rxGetUserPending} from "../../state-management/actions/usersActions";
import { rxSetUserRole } from '../../state-management/actions/userRoleActions';
import { USER_ROLE } from '../../core/constants/enums';

class UserController extends Controller{
    constructor(usersOperations) {
        super();
        this.usersOperations = usersOperations;
    }

    getUser = async (store , action) => {
        store.dispatch(rxGetUserPending());
        const response = await this.usersOperations.getUser();
        if (response !== null) {
            store.dispatch(rxSetUserRole(USER_ROLE[response.type] || 'Admin'));
            store.dispatch(rxGetUserDone(response));
        }
    }
}

export default UserController;
