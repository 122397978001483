import React, { useState } from 'react';
import './navigation.css';
import { connect } from 'react-redux';
import { Icon, Option } from 'bet-core-ui';
import PopoverWithHover from '../popoverWithHover';
import useTranslation from '../../../hooks/useTranslation';
import { withRouter, useLocation, NavLink } from 'react-router-dom';
import { navigationConstants } from '../../../core/constants/navigationConstants';


const Navigation = React.memo(({userRole}) => {

    const translate = useTranslation();
    const location = useLocation();
    // const [parentNavHover, setParentNavHover ] = useState(false);
    const [childNavHover, setChildNavHover ] = useState(false);

    return (
        <ul className={'bo-nav'}>
            <li/>
            <li>
                {
                    navigationConstants.map(({subSections, text, to, roles}) => {
                        let activeClassName;
                        if (Array.isArray(subSections)) {
                            activeClassName = location.pathname.split('/').some(item => item === to.slice(1)) ? 'nav-item-active' : ''
                        }

                        if (!roles.includes(userRole)) return null;
                        return (
                            !subSections ? <NavLink
                                    key={to}
                                    to={to}
                                    className={'nav-item'}
                                    activeClassName={'nav-item-active'}
                                >
                                    <span>{translate(text)}</span>
                                </NavLink> :
                                <PopoverWithHover
                                    childRenOpen={childNavHover}
                                    setChildrenOpen={setChildNavHover}
                                    key={to}
                                    align={'start'}
                                    Content={<div className="bo-submenu popover-top-bottom-padding">
                                        {
                                            subSections.map(section => {
                                                if (!section.roles.includes(userRole)) return null;
                                                if (section.showInner) {
                                                    return (
                                                        <PopoverWithHover
                                                            key={to}
                                                            align={'start'}
                                                            position={'right'}
                                                            Content={
                                                                <div className="bo-submenu popover-top-bottom-padding">
                                                                    {
                                                                        section.subSections.map(subSection => {
                                                                            if (!subSection.roles.includes(userRole)) return null;
                                                                            return (
                                                                                <Option
                                                                                    key={subSection.to}
                                                                                    screenType={'desktop'}
                                                                                    title={subSection.text}
                                                                                    content={<NavLink to={to + section.to + subSection.to}/>}
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </div>}
                                                            extendTargetWidth={false}
                                                            maxHeight={220}
                                                        >
                                                            <div className={location.pathname.split('/').some(item => item === section.to.slice(1)) ? activeClassName : '' }>
                                                                <Option
                                                                    key={section.to}
                                                                    screenType={'desktop'}
                                                                    title={section.text}
                                                                    rightCustomElement={<Icon
                                                                        type="bc-icon-arrow-right"/>}
                                                                />
                                                            </div>
                                                        </PopoverWithHover>
                                                    )
                                                }
                                                return (
                                                    <Option
                                                        key={section.to}
                                                        screenType={'desktop'}
                                                        title={section.text}
                                                        content={<NavLink to={to + section.to}/>}
                                                    />
                                                )
                                            })
                                        }
                                    </div>}
                                    extendTargetWidth={false}
                                    maxHeight={220}
                                >
                                    <div className={'nav-item ' + activeClassName}>
                                        <span>{translate(text)}</span>
                                        <Icon type="bc-icon-arrow-down"/>
                                    </div>

                                </PopoverWithHover>
                        )
                    })
                }

            </li>

            <li/>
        </ul>
    )
});

const mapStateTpProps = state => ({
    userRole: state.userRole
});

export default withRouter(connect(mapStateTpProps)(Navigation));
