import HiLoBackofficeApiHandler from '../HiLoBackofficeApiHandler';


const END_POINT = {
  prefix: 'UserTournaments',
  report: 'report',
  rangeReport: 'rangeReport',
};

class UserTournamentsApiHandler extends HiLoBackofficeApiHandler {

  getUserTournamentsReport(query) {
    return super.get(END_POINT.report, query)
  }

  getUserTournamentsRangeReport(query) {
    return super.get(END_POINT.rangeReport, query)
  }

}

const userTournamentsApiHandler = new UserTournamentsApiHandler(END_POINT.prefix);
export default userTournamentsApiHandler;
