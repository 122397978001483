import Subscriber from '../../core/classes/Subscriber';

class WebSocketService {
  constructor() {
    this.state = null;
    this.subscribers = [];
  }

  subscribe(subscriberFunc) {
    const subscriber = new Subscriber(subscriberFunc);
    this.subscribers.push(subscriber);
    return subscriber.id;
  }

  unSubscribe(id) {
    this.subscribers = this.subscribers.filter(sub => sub.id !== id);
  }

  setState(data) {
    this.state = data;
    this._notify();
  }

  _notify() {
    if(this.state === null || this.state === undefined || !this.subscribers.length) return;
    this.subscribers.forEach(subscriber => subscriber.callback(this.state))
  }

}

const
  webSocketService = new WebSocketService();
export default webSocketService;