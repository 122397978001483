import Controller from '../../core/classes/AppController';
import {
    rxGetBetTypesPending, rxGetBetTypesDone
} from '../../state-management/actions/betTypesActions';

class BetTypesController extends Controller {
    constructor(betTypesOperation) {
        super();
        this.betTypesOperation = betTypesOperation;
    }

    getBetTypes = async (store, action) => {
        try {
            store.dispatch(rxGetBetTypesPending());
            const response = await this.betTypesOperation.getBetTypes(action.payload);
            store.dispatch(rxGetBetTypesDone(response));
        } catch (e) {
            return new Error(e);
        }
    };
}

export default BetTypesController;
