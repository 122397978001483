import { combineReducers } from 'redux';
import createAsyncReducer from '../../core/helpers/createAsyncReducer';
import { TOURNAMENT_TYPES } from '../../core/constants/actionTypes';

export const tournamentsReducer = combineReducers({
    tournaments: createAsyncReducer(TOURNAMENT_TYPES.RX_GET_TOURNAMENTS, []),
    tournament: createAsyncReducer(TOURNAMENT_TYPES.RX_GET_TOURNAMENT, null),
    players: createAsyncReducer(TOURNAMENT_TYPES.RX_GET_TOURNAMENT_PLAYERS, {}),
    partnersTournament: createAsyncReducer(TOURNAMENT_TYPES.RX_GET_PARTNERS_TOURNAMENT, [])
});

export default tournamentsReducer;
