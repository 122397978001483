import HiLoBackofficeApiHandler from "../HiLoBackofficeApiHandler";


const END_POINT = {
    prefix : 'Partners',
    mainLimit : 'mainLimit',
    betTypeConfigs: 'betTypeConfigs',
    GetPartnerCurrencies : 'currencies',
    partnerContestType: 'partnerContestType',
    partnerContestTypesConfig: 'partnerContestTypesConfig',
    betTypeAndLimitConfigs:'betTypeAndLimitConfigs',
    betTypeAndLimit : 'betTypeAndLimit',
    status : 'status',
    demo : 'demo'

};
class PartnersApiHandler extends HiLoBackofficeApiHandler{
    getPartners(query){
        return super.get( '', query)
    }
    getAllPartners(query){
        return super.get( '',query)
    }
    getPartnersMainLimit(query){
        return super.get(END_POINT.mainLimit, query)
    }

    getPartnersBetTypeConfigs(query){
        return super.get(END_POINT.betTypeConfigs, query)
    }

    getPartnersCurrencies(query){
        return super.get(END_POINT.GetPartnerCurrencies , query)
    }

    getPartnersBetTypeLimitConfigs(query){

        return super.get(END_POINT.betTypeAndLimitConfigs , query)
    }

    getPartnerContestType(query){
        return super.get(END_POINT.partnerContestType , query)
    }

    getPartnerSelectedCurrencies(query){
        return super.get(END_POINT.GetPartnerCurrencies , query)
    }

    editPartnerMainLimits(data){

        const {mainLimitId , ...restData} = data
        return super.put( `${END_POINT.mainLimit}?mainlimitId=${mainLimitId}`, restData);
    }

    setPartnerStatus(data){
        const{partnerId ,...restData} = data;
         return super.put(`${partnerId}/${END_POINT.status}` , restData )
    }
    editPartnerMarkets(data){
        return super.put(END_POINT.betTypeAndLimit , data)
    }

    // add Partner ApiHandler
    addPartner(data){
        return super.post('' , data)
    }

    getPartnerContestTypeConfig(query){
        return super.get(END_POINT.partnerContestTypesConfig,query)
    }

    editPartnerDemo(data){
        const { partnerId, isDemo } = data;
        return super.put( `${partnerId}/${END_POINT.demo}`, '' ,{isDemo} )
    }

}

const partnersApiHandler = new PartnersApiHandler(END_POINT.prefix);
export default partnersApiHandler;
