import {combineReducers} from "redux";
import createAsyncReducer from "../../core/helpers/createAsyncReducer";
import createReducer from '../../core/helpers/createReducer';
import {MAINTENANCES} from "../../core/constants/actionTypes";

export const maintenancesReducer = combineReducers({
    maintenances: createAsyncReducer(MAINTENANCES.RX_GET_MAINTENANCES , {}),
});

export const IsFinishedMaintenances = createReducer(false, {
    [MAINTENANCES.RX_IS_FINISHED_MAINTENANCES]: (state, action) => action.payload
});