import {USERS} from "../../core/constants/actionTypes";

export const cntrlGetUser = payload => ({
    type : USERS.CNTRL_GET_USERS,
    payload
});

export const rxGetUser = payload => ({
    type : USERS.RX_GET_USERS,
    payload
});
export const rxGetUserPending = payload => ({
    type : USERS.RX_GET_USERS_PENDING,
    payload
});

export const rxGetUserDone = payload => ({
    type : USERS.RX_GET_USERS_DONE,
    payload
});