import Operations from "../../../core/classes/AppOperation";
import responseErrorCheck from "../../../core/helpers/responseErrorChecker";

class PartnersOperation extends Operations{
    constructor(partnersApiHandler){
        super();
        this.partnersApiHandler = partnersApiHandler;
    }

    async getPartners(query){
        const response = await this.partnersApiHandler.getPartners(query);
        return responseErrorCheck(response);
    }

    async getAllPartners(query){
        const response = await this.partnersApiHandler.getAllPartners(query);
        return responseErrorCheck(response);
    }

    async getPartnersMainLimit(query){
        const response =await this.partnersApiHandler.getPartnersMainLimit(query);
        return responseErrorCheck(response);
    }

    async getPartnersBetTypeConfigs(query){
        const response =await this.partnersApiHandler.getPartnersBetTypeConfigs(query);
        return responseErrorCheck(response);
    }
    async getPartnersCurrencies(query){
        const response = await this.partnersApiHandler.getPartnersCurrencies(query);
        return responseErrorCheck(response);
    }

    async getPartnersBetTypeLimitConfigs(query){
        const response = await this.partnersApiHandler.getPartnersBetTypeLimitConfigs(query);
        return responseErrorCheck(response);
    }

    async getPartnerContestType(query){
        const response = await this.partnersApiHandler.getPartnerContestType(query);
        return responseErrorCheck(response);
    }
    async getPartnerSelectedCurrencies(query){
        const response = await this.partnersApiHandler.getPartnerSelectedCurrencies(query);
        return responseErrorCheck(response);
    }

    async editPartnerMainLimits(data){
        const response = await this.partnersApiHandler.editPartnerMainLimits(data);
        return responseErrorCheck(response);
    }
    async setPartnerStatus(data) {
        const response =  await this.partnersApiHandler.setPartnerStatus(data);
        return responseErrorCheck(response);
    }
    async editPartnerMarkets(data){
        const response = await this.partnersApiHandler.editPartnerMarkets(data);
        return responseErrorCheck(response);
    }


    // add Partner operation
    async addPartner(data){
        const response = await this.partnersApiHandler.addPartner(data);
        return responseErrorCheck(response);
    };

    async getPartnerContestTypeConfig(query){
        const response = await this.partnersApiHandler.getPartnerContestTypeConfig(query);
        return responseErrorCheck(response)
    }

    async editPartnerDemo(data){
        const response = await this.partnersApiHandler.editPartnerDemo(data);
        return responseErrorCheck(response);
    }

}

export default PartnersOperation;
