import Controller from '../../core/classes/AppController';
import {
    rxGetTournamentsPending,
    rxGetTournamentsDone,
    rxGetTournamentPending,
    rxGetTournamentDone,
    rxGetTournamentPlayersPending,
    rxGetTournamentPlayersDone,
    rxGetPartnersTournamentPending,
    rxGetPartnersTournamentDone,
} from '../../state-management/actions/tournamentActions';

class TournamentController extends Controller {
    constructor(tournamentOperations) {
        super();
        this.tournamentOperations = tournamentOperations;
    }

    getTournaments = async (store, action) => {
        try {
            store.dispatch(rxGetTournamentsPending());
            const response = await this.tournamentOperations.getTournaments(action.payload);
            store.dispatch(rxGetTournamentsDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    getTournament = async (store, action) => {
        try {
            store.dispatch(rxGetTournamentPending());
            const response = await this.tournamentOperations.getTournament(action.payload);
            store.dispatch(rxGetTournamentDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    addTournament = async (store, action) => {
        return await this.tournamentOperations.addTournament(action);
    };

    updateTournament = async (store, action) => {
        return this.tournamentOperations.updateTournament(action);
    };

    cancelTournament = async (store, action) => {
        return this.tournamentOperations.cancelTournament(action);
    };

    getTournamentPlayers = async (store, action) => {
        try {
            store.dispatch(rxGetTournamentPlayersPending());
            const response = await this.tournamentOperations.getTournamentPlayers(action.payload);
            store.dispatch(rxGetTournamentPlayersDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    getPartnersTournament = async (store, action) => {
        try {
            store.dispatch(rxGetPartnersTournamentPending());
            const response = await this.tournamentOperations.getPartnersTournament(action.payload);
            store.dispatch(rxGetPartnersTournamentDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

}

export default TournamentController;
