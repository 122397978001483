import Operations from "../../../core/classes/AppOperation";
import responseErrorCheck from "../../../core/helpers/responseErrorChecker";

class LanguagesOperation extends Operations{
    constructor(languagesApiHandler) {
        super();
        this.languagesApiHandler = languagesApiHandler;
    }

    async getTranslationsByCode(languageId){
        const resposne = await this.languagesApiHandler.getTranslationsByCode(languageId);
        return responseErrorCheck(resposne);
    }


    async getLanguages(data){
        const response = await this.languagesApiHandler.getLanguages(data);
        return responseErrorCheck(response)
    }
};

export default LanguagesOperation;
