import HiLoBackofficeApiHandler from '../HiLoBackofficeApiHandler';

const END_POINT = {
    prefix: 'FreeBets',
    reports: 'reports',
    cancel: 'cancel'
};

class FreeBetApiHandler extends HiLoBackofficeApiHandler {

    getFreeBets(query) {
        return super.get('', query);
    }

    getFreeBet(query) {
        const { freeBetId } = query;
        return super.get(`${freeBetId}`);
    }

    getFreeBetReport(query) {
        return super.get(END_POINT.reports, query);
    }

    addFreeBet(data) {
        return super.post('', data);
    }

    updateFreeBet(data) {
        const { id, ...freeBetData } = data;
        return super.put('', freeBetData, { id });
    }

    cancelFreeBet(data) {
        return super.put(END_POINT.cancel,'', data);
    }

}

const freeBetApiHandler = new FreeBetApiHandler(END_POINT.prefix);
export default freeBetApiHandler;
