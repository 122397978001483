import {combineReducers} from "redux";
import createAsyncReducer from "../../core/helpers/createAsyncReducer";
import {REPORT_TYPES} from "../../core/constants/actionTypes";

export const reportsReducer = combineReducers({
    betReport : createAsyncReducer(REPORT_TYPES.RX_GET_BET_REPORT , {
        totalCount : 0,
        filteredItems : [],
        totalAmount : 0,
        totalWinAmount : 0
    }),
    dashboardReport : createAsyncReducer(REPORT_TYPES.RX_GET_DASHBOARD_REPORT , null),
    financialReport: createAsyncReducer(REPORT_TYPES.RX_GET_FINANCIAL_REPORT,{
        totalCount : 0,
        filteredItems : [],
        totalAmount : 0,
        totalWinAmount : 0
    }),
    topBets : createAsyncReducer(REPORT_TYPES.RX_GET_TOP_BETS , {}),
    userReport : createAsyncReducer(REPORT_TYPES.RX_GET_USER_REPORT, {
        filteredItems : [],
        totalCount : 0
    }),
    contestReport : createAsyncReducer(REPORT_TYPES.RX_GET_CONTEST_REPORT , {
        count : 0,
        contestReports : [],
        totalProfitOrLose : 0,
        totalAmount : 0,
        totalWinAmount : 0,

    }),

    wonBets : createAsyncReducer(REPORT_TYPES.RX_GET_TOP_WIN_BETS , {
        totalCount : 0,
        filteredItems : []
    }),
    loseBets : createAsyncReducer(REPORT_TYPES.RX_GET_LOSE_BET_REPORT , {
        totalCount : 0,
        filteredItems : []
    }),
    marketReport : createAsyncReducer(REPORT_TYPES.RX_GET_MARKET_REPORT , {
        totalCount : 0,
        totalWinAmount : 0,
        totalAmount : 0,
        filteredItems : []
    }) ,
});
