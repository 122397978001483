const DONE = 'DONE';
const REJECTED = 'REJECTED';
const PENDING = 'PENDING';

const createAsyncReducer = (prefix, initialState) => (state = { isLoading: false, data: initialState }, action) => {
  const { type } = action;
  switch ( type ) {
    case `${prefix}_${PENDING}`:

      return { data: state.data, isLoading: true, error: null, };
    case `${prefix}_${DONE}`:

      return {
        ...state,
        data: action.payload || initialState,
        isLoading: false,
        error: null
      };
    case `${prefix}_${REJECTED}`:
      return {
        ...state,
        data: {},
        isLoading: false,
        error: action.payload
      };
    default:
      return state
  }
};

export default createAsyncReducer;
