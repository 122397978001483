import React, { useEffect, useState, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { cntrlAppInit } from '../../../state-management/actions/appActions';
import { rxSetIsAuth } from '../../../state-management/actions/tokenActions';
import { defaultPath } from '../../../routing/router';
import { BusyLoader } from 'bet-core-ui';
import {cntrlGetUser} from '../../../state-management/actions/usersActions';

const Splash = ({init , setIsAuth , isAuth, history , location , children, getUser}) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    init().then(isValid => {
      setIsAuth(!!isValid);

      if(!isValid) {
        history.push('/login');
        setLoading(false)
      } else {
        setLoading(false);
        // getUser();
        history.push({
          path: location.pathname,
          search: location.search
        })
      }
    })
  }, [isAuth]);


  return isLoading 
  ? <div style={{width: '100%', height: '100vh', display: "flex", justifyContent: 'center', alignItems: 'center'}}><BusyLoader isBusy type="spinner" spinnerSize='big'/></div>
  : <Suspense fallback={<BusyLoader isBusy type="spinner"/>}>{children}</Suspense>
};


const mapStateToProps = state => ({
  isAuth: state.token.isAuthReducer
});

const mapDispatchToProps = dispatch => ({
  getUser : () => dispatch(cntrlGetUser()),
  init: () => dispatch(cntrlAppInit()),
  setIsAuth: bool => dispatch(rxSetIsAuth(bool)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Splash));
