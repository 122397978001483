export const authSchemeFactory = (AuthScheme = 2) =>{
  switch ( AuthScheme ){
    case 2 :
      return (username, password) =>{


        return {
          AuthScheme,
          Data : {
            partnerId : null,
            username,
            password
          },

        }
      };
    default :
      throw new Error('autoscheme is not defined')
  }
};