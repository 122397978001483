import Operations from "../../../core/classes/AppOperation";
import responseErrorCheck from "../../../core/helpers/responseErrorChecker";

class ReportsOperation extends Operations{
    constructor(reportsApiHandler){
        super();
        this.reportsApiHandler = reportsApiHandler;
    }

    async getBetReport(query){
        if(Object.keys(query).includes('GameId')){
            let {DateFrom ,...queryWithOutDate} = query;
            const response = await this.reportsApiHandler.getBetReport(queryWithOutDate);
            return responseErrorCheck(response);
        }else {
            const response = await this.reportsApiHandler.getBetReport(query);
            return responseErrorCheck(response);
        }
    }
    async getFinancialReport(query){
        const response = await this.reportsApiHandler.getFinancialReport(query);

        responseErrorCheck(response);
        const res = response.result;
        res.filteredItems = res.filteredItems.map(item =>{
            const {financial , ...restItem} = item;
            return { ...restItem , ...financial}
        })
        return res
    }

    async getUserReport(query){
        const response = await this.reportsApiHandler.getUserReport(query);
        return responseErrorCheck(response);
    }

    async getContestReport(query){
        const response = await this.reportsApiHandler.getContestReport(query);
        return responseErrorCheck(response);
    }

    async getDashboardReport(query){
        const response = await this.reportsApiHandler.getDashboardReport(query);
        return  responseErrorCheck(response);
    }

    async getTopBetReport(query){
        const response = await this.reportsApiHandler.getTopBetReport(query);
        return responseErrorCheck(response);
    }

    async getTopWinBets(query){
        const response = await this.reportsApiHandler.getTopWinBets(query);
        return responseErrorCheck(response)
    }

    async getLoseBetReport(query){
        const response = await this.reportsApiHandler.getLoseBetReport(query);
        return responseErrorCheck(response);
    }

    async getMarketReport(query){
        const response = await this.reportsApiHandler.getMarketReport(query);
        return responseErrorCheck(response);
    }
}

export default ReportsOperation;
