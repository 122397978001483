import Operations from '../../../core/classes/AppOperation';
import responseErrorCheck from '../../../core/helpers/responseErrorChecker';

class FreeBetOperations extends Operations {
    constructor(freeBetApiHandler) {
        super();
        this.freeBetApiHandler = freeBetApiHandler;
    }

    async getFreeBets(query) {
        const response = await this.freeBetApiHandler.getFreeBets(query);
        return responseErrorCheck(response);
    };

    async getFreeBet(query) {
        const response = await this.freeBetApiHandler.getFreeBet(query);
        return responseErrorCheck(response);
    };

    async getFreeBetReport(query) {
        const response = await this.freeBetApiHandler.getFreeBetReport(query);
        return responseErrorCheck(response);
    };


    async addFreeBet(action) {
        const response = await this.freeBetApiHandler.addFreeBet(action.payload);
        return responseErrorCheck(response);
    }

    async updateFreeBet(action) {
        const response = await this.freeBetApiHandler.updateFreeBet(action.payload);
        return responseErrorCheck(response);
    }

    async cancelFreeBet(action) {
        const response = await this.freeBetApiHandler.cancelFreeBet(action.payload);
        return responseErrorCheck(response);
    }

}

export default FreeBetOperations;
