import React , {useEffect} from 'react';
import {connect} from 'react-redux';
import {Alert} from "bet-core-ui";
import './index.css';


const actions = {
    // languageAdded : rxLanguageAdded,
    // languageEdited : rxLanguageEdited
};

const Alertifier = ({alerts ,closeAlert ,  ...props}) => {
    const alertKeys = Object.keys(alerts).filter(item => !!alerts[item]);

    // useEffect(()=>{
    //     alertKeys.forEach(key => setTimeout(()=>closeAlert(actions[key]), 40000))
    // } , [alerts]);

    return(
        <>
            {
                alertKeys.map(item => (
                    <div className={'alertifier'}>
                        <Alert
                            iconProps
                            type={alerts[item].type}
                            title={<div>{alerts[item].title}</div>}
                            message={<div>{alerts[item].message}</div>}
                            onClose={()=>closeAlert(actions[item])}
                        />
                    </div>
                ))
            }
        </>
    )
};


const mapStateToProps = state => ({
    // alerts : alertifireAccessor(state)
});

const mapDispatchToProps = dispatch => ({
    // closeAlert : action=> dispatch(action(null))
});

export default connect(mapStateToProps , mapDispatchToProps)(Alertifier);
