export const BEARER = 'Bearer';

export const AUTH = 'AUTH';
export const ACCESS = 'access';

export const PartnerViewMods = ['CLASSIC_MODE', 'ALTERNATI'];


export const betTypeEnumData  = ['Red_2',
    "Black_2",
    "Number_2",
    "Hi_2",
    "Red_3",
    "Black_3",
    "Number_3",
    "Hi_3",
    "Lo_3",
    "Red_4",
    "Black_4",
    "Number_4",
    "Hi_4",
    "Lo_4",
    "Red_5",
    "Black_5",
    "Number_5",
    "Hi_5",
    "Lo_5",
    "Red_6",
    "Black_6",
    "Number_6",
    "Hi_6",
    "Lo_6",
    "Red_7",
    "Black_7",
    "Number_7",
    "Hi_7",
    "Lo_7",
    "Red_8",
    "Black_8",
    "Number_8",
    "Hi_8",
    "Lo_8",
    "Red_9",
    "Black_9",
    "Number_9",
    "Hi_9",
    "Lo_9",
    "Red_J",
    "Black_J",
    "J",
    "Hi_J",
    "Lo_J",
    "Red_Q",
    "Black_Q",
    "Q",
    "Hi_Q",
    "Lo_Q",
    "Red_K",
    "Black_K",
    "K",
    "Hi_K",
    "Lo_K",
    "Red_A",
    "Black_A",
    "A",
    "Lo_A",
    "Red",
    "Black",
    "Numbers_2_to_9",
    "Joker",
    "J_Q_K_A",
    "J_Q",
    "K_A"];

export const userTypeData = [
    {
        id : true ,
        name : 'TEST'
    } ,
    {
        id : false ,
        name : 'REAL'
    }];

export const DATE_FORMAT_D_M_Y = 'DD/MM/YYYY';
export const DATE_FORMAT_D_M_Y_H_M = 'DD/MM/YYYY HH:mm';
