import {CONTEST} from '../../core/constants/actionTypes'

export const cntrlGetContestTypes = payload =>({
    type: CONTEST.CNTRL_GET_CONTEST_TYPES,
    payload,
});

export const rxGetContestTypes = payload =>({
    type: CONTEST.RX_GET_CONTEST_TYPES,
    payload,
});

export const rxGetContestTypesDone = payload =>({
    type: CONTEST.RX_GET_CONTEST_TYPES_DONE,
    payload,
});

export const rxGetContestTypesPending = payload =>({
    type: CONTEST.RX_GET_CONTEST_TYPES_PENDING,
    payload,
});

export const cntrlAddContestType = payload =>({
    type: CONTEST.CNTRL_ADD_CONTEST_TYPE,
    payload,
});

export const rxAddContestType = payload =>({
    type: CONTEST.RX_ADD_CONTEST_TYPE,
    payload,
});

export const rxAddContestTypeDone = payload =>({
    type: CONTEST.RX_ADD_CONTEST_TYPE_DONE,
    payload,
});

export const rxAddContestTypePending = payload =>({
    type : CONTEST.RX_ADD_CONTEST_TYPE_PENDING,
    payload,
});

export const cntrlEditContestType = payload =>({
    type:CONTEST.CNTRL_EDIT_CONTEST_TYPE,
    payload,
});

export const rxEditContestType = payload =>({
    type:CONTEST.RX_EDIT_CONTEST_TYPE,
    payload,
});

export const rxEditContestTypeDone = payload =>({
    type:CONTEST.RX_EDIT_CONTEST_TYPE_DONE,
    payload,
});

export const rxEditContestTypePending = payload =>({
    type:CONTEST.RX_EDIT_CONTEST_TYPE_PENDING,
    payload,
});

export const cntrlDeletePartnersFromContestType = payload =>({
    type:CONTEST.CNTRL_DELETE_PARTNERS_FROM_CONTEST_TYPE,
    payload,
});

export const rxDeletePartnersFromContestType = payload =>({
    type:CONTEST.RX_DELETE_PARTNERS_FROM_CONTEST_TYPE,
    payload,
});

export const rxDeletePartnersFromContestTypeDone = payload =>({
    type:CONTEST.RX_DELETE_PARTNERS_FROM_CONTEST_TYPE_DONE,
    payload,
});

export const rxDeletePartnersFromContestTypePending = payload =>({
    type:CONTEST.RX_DELETE_PARTNERS_FROM_CONTEST_TYPE_PENDING,
    payload,
});
