import Controller from '../../core/classes/AppController';
import {
    rxGetFreeBetsPending,
    rxGetFreeBetsDone,
    rxGetFreeBetPending,
    rxGetFreeBetDone,
    rxGetFreeBetReportPending,
    rxGetFreeBetReportDone,
} from '../../state-management/actions/freeBetActions';

class FreeBetController extends Controller {
    constructor(freeBetOperations) {
        super();
        this.freeBetOperations = freeBetOperations;
    }

    getFreeBets = async (store, action) => {
        try {
            store.dispatch(rxGetFreeBetsPending());
            const response = await this.freeBetOperations.getFreeBets(action.payload);
            store.dispatch(rxGetFreeBetsDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    getFreeBet = async (store, action) => {
        try {
            store.dispatch(rxGetFreeBetPending());
            const response = await this.freeBetOperations.getFreeBet(action.payload);
            store.dispatch(rxGetFreeBetDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    getFreeBetReport = async (store, action) => {
        try {
            store.dispatch(rxGetFreeBetReportPending());
            const response = await this.freeBetOperations.getFreeBetReport(action.payload);
            store.dispatch(rxGetFreeBetReportDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    addFreeBet = async (store, action) => {
        return await this.freeBetOperations.addFreeBet(action);
    };

    updateFreeBet = async (store, action) => {
        return this.freeBetOperations.updateFreeBet(action);
    };

    cancelFreeBet= async (store, action) => {
        return this.freeBetOperations.cancelFreeBet(action);
    };

}

export default FreeBetController;
