import * as controllers from './controllerFactory';
import * as TYPES from '../core/constants/actionTypes';
import { SIGNAL_R_TYPES } from '../core/constants/actionTypes';

export const configs = {
    //init
    [TYPES.APP_INIT_TYPES.CNTRL_APP_INIT]: controllers.appController.init,
    [TYPES.AUTH_TYPES.CNTRL_LOGOUT]: controllers.tokenController.logOut,

    //auth types
    [TYPES.AUTH_TYPES.CNTRL_LOG_IN]: controllers.tokenController.authenticate,
    [TYPES.AUTH_TYPES.CNTRL_REFRESH_TOKEN]: controllers.tokenController.refreshToken,

    //reports
    [TYPES.REPORT_TYPES.CNTRL_GET_BET_REPORT]: controllers.reportsController.getBetReport,
    [TYPES.REPORT_TYPES.CNTRL_GET_FINANCIAL_REPORT]: controllers.reportsController.getFinancialReport,//todo
    [TYPES.REPORT_TYPES.CNTRL_GET_USER_REPORT]: controllers.reportsController.getUserReport,
    [TYPES.REPORT_TYPES.CNTRL_GET_CONTEST_REPORT]: controllers.reportsController.getContestReport,
    [TYPES.REPORT_TYPES.CNTRL_GET_DASHBOARD_REPORT]: controllers.reportsController.getDashboardReport,
    [TYPES.REPORT_TYPES.CNTRL_GET_TOP_BETS]: controllers.reportsController.getTopBetReport,
    [TYPES.REPORT_TYPES.CNTRL_GET_TOP_WIN_BETS]: controllers.reportsController.getTopWinBets,
    [TYPES.REPORT_TYPES.CNTRL_GET_LOSE_BET_REPORT]: controllers.reportsController.getLoseBetReport,
    [TYPES.REPORT_TYPES.CNTRL_GET_MARKET_REPORT]: controllers.reportsController.getMarketReport,

    //languages
    [TYPES.LANGUAGES.CNTRL_GET_TRANSLATIONS]: controllers.languagesController.getTranslationsByCode,
    [TYPES.LANGUAGES.CNTRL_GET_LANGUAGES]: controllers.languagesController.getLanguages,

    //partners
    [TYPES.PARTNERS.CNTRL_GET_PARTNERS]: controllers.partnersController.getPartners,
    [TYPES.PARTNERS.CNTRL_GET_ALL_PARTNERS]: controllers.partnersController.getAllPartners,
    [TYPES.PARTNERS.CNTRL_GET_PARTNERS_MAIN_LIMIT]: controllers.partnersController.getPartnersMainLimit,
    [TYPES.PARTNERS.CNTRL_GET_PARTNERS_BET_TYPE_CONFIGS]: controllers.partnersController.getPartnersBetTypeConfigs,
    [TYPES.PARTNERS.CNTRL_GET_PARTNERS_CURRENCIES]: controllers.partnersController.getCurrencies,
    [TYPES.PARTNERS.CNTRL_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS]: controllers.partnersController.getPartnersBetTypeLimitConfigs,
    [TYPES.PARTNERS.CNTRL_GET_PARTNERS_CONTEST_TYPE]: controllers.partnersController.getPartnerContestType,
    [TYPES.PARTNERS.CNTRL_GET_SELECTED_PARTNERS_CURRENCIES]: controllers.partnersController.getPartnerSelectedCurrencies,
    [TYPES.PARTNERS.CNTRL_EDIT_PARTNER_MAIN_LIMITS]: controllers.partnersController.editPartnerMainLimits,
    [TYPES.PARTNERS.CNTRL_EDIT_GET_PARTNERS_STATUS]: controllers.partnersController.setPartnerStatus,
    [TYPES.PARTNERS.CNTRL_EDIT_PARTNER_MARCETS]: controllers.partnersController.editPartnerMarkets,
    [TYPES.PARTNERS.CNTRL_GET_PARTNERS_CONTEST_TYPES_CONFIG]: controllers.partnersController.getPartnerContestTypeConfig,
    [TYPES.PARTNERS.CNTRL_EDIT_PARTNER_DEMO]: controllers.partnersController.editPartnerDemo,

    // add partners config
    [TYPES.PARTNERS.CNTRL_ADD_PARTNERS]: controllers.partnersController.addPartner,

    //users
    [TYPES.USERS.CNTRL_GET_USERS]: controllers.usersController.getUser,

    //contest types
    [TYPES.CONTEST.CNTRL_GET_CONTEST_TYPES]: controllers.contestTypesController.getContestTypes,
    [TYPES.CONTEST.CNTRL_ADD_CONTEST_TYPE]: controllers.contestTypesController.addContestType,
    [TYPES.CONTEST.CNTRL_EDIT_CONTEST_TYPE]: controllers.contestTypesController.editContestType,
    [TYPES.CONTEST.CNTRL_DELETE_PARTNERS_FROM_CONTEST_TYPE]: controllers.contestTypesController.deletePartnersFromContestType,

    //Maintenances
    [TYPES.MAINTENANCES.CNTRL_GET_MAINTENANCES]: controllers.maintenancesController.getMaintenances,
    [TYPES.MAINTENANCES.CNTRL_ADD_MAINTENANCES]: controllers.maintenancesController.addMaintenances,
    [TYPES.MAINTENANCES.CNTRL_CANCEL_MAINTENANCES]: controllers.maintenancesController.cancelMaintenances,
    [TYPES.MAINTENANCES.CNTRL_EDIT_END_DATE]: controllers.maintenancesController.editEndDate,

// SignalR
    [SIGNAL_R_TYPES.CNTRL_SIGNAL_R_CONNECT]: controllers.signalRController.connect,

    //BetTypes
    [TYPES.BET_TYPES.CNTRL_GET_BET_TYPES]: controllers.betTypesController.getBetTypes,

    //tournaments
    [TYPES.TOURNAMENT_TYPES.CNTRL_ADD_TOURNAMENT]: controllers.tournamentController.addTournament,
    [TYPES.TOURNAMENT_TYPES.CNTRL_UPDATE_TOURNAMENT]: controllers.tournamentController.updateTournament,
    [TYPES.TOURNAMENT_TYPES.CNTRL_GET_TOURNAMENTS]: controllers.tournamentController.getTournaments,
    [TYPES.TOURNAMENT_TYPES.CNTRL_GET_TOURNAMENT]: controllers.tournamentController.getTournament,
    [TYPES.TOURNAMENT_TYPES.CNTRL_CANCEL_TOURNAMENT]: controllers.tournamentController.cancelTournament,
    [TYPES.TOURNAMENT_TYPES.CNTRL_GET_TOURNAMENT_PLAYERS]: controllers.tournamentController.getTournamentPlayers,
    [TYPES.TOURNAMENT_TYPES.CNTRL_GET_PARTNERS_TOURNAMENT]: controllers.tournamentController.getPartnersTournament,

    //userTournaments
    [TYPES.USER_TOURNAMENT_TYPES.CNTRL_GET_USER_TOURNAMENTS_REPORT]: controllers.userTournamentsController.getUserTournamentsReport,
    [TYPES.USER_TOURNAMENT_TYPES.CNTRL_GET_USER_TOURNAMENTS_RANGE_REPORT]: controllers.userTournamentsController.getUserTournamentsRangeReport,

    //freeBets
    [TYPES.FREE_BET_TYPES.CNTRL_GET_FREE_BETS]: controllers.freeBetController.getFreeBets,
    [TYPES.FREE_BET_TYPES.CNTRL_GET_FREE_BET]: controllers.freeBetController.getFreeBet,
    [TYPES.FREE_BET_TYPES.CNTRL_GET_FREE_BET_REPORT]: controllers.freeBetController.getFreeBetReport,
    [TYPES.FREE_BET_TYPES.CNTRL_ADD_FREE_BET]: controllers.freeBetController.addFreeBet,
    [TYPES.FREE_BET_TYPES.CNTRL_EDIT_FREE_BET]: controllers.freeBetController.updateFreeBet,
    [TYPES.FREE_BET_TYPES.CNTRL_CANCEL_FREE_BET]: controllers.freeBetController.cancelFreeBet,
};

export default configs;
