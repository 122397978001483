import HiLoBackofficeApiHandler from '../HiLoBackofficeApiHandler';

const END_POINT = {
    prefix: 'BetTypes',
};


class BetTypesApiHandler extends HiLoBackofficeApiHandler {

    getBetTypes(query) {
        return super.get('', query)
    }

}


const betTypesApiHandler = new BetTypesApiHandler(END_POINT.prefix);
export default betTypesApiHandler;
