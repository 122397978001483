import React, {Suspense, useEffect} from 'react';
import DefaultRoutes from '../../routing/DefaultRoutes';
import Header from '../components/header';
import {withRouter , useLocation} from 'react-router-dom';
import Navigation from '../components/navigation/navigation';
import Alertifier from "../components/alertifier";
import {connect} from 'react-redux';
import {cntrlGetPartnerCurrencies} from "../../state-management/actions/partnersAction";
import {Toaster} from "bet-core-ui";

const App = ({
               getCurrencies,
    ...props
             }) => {



  useEffect(() => {
    getCurrencies();
  } , [])

  return(
    <>
      <Header/>
        <Suspense fallback={<p>Loading...</p>}>
          <div className={'main-content'}>
            <Navigation/>
            <Toaster/>
            {/*<Alertifier/>*/}
            <DefaultRoutes {...props}/>
          </div>
        </Suspense>

    </>
  )
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
  getCurrencies : data => dispatch(cntrlGetPartnerCurrencies(data))
})

export default withRouter(connect(mapStateToProps , mapDispatchToProps)(App));
