import Operations from "../../../core/classes/AppOperation";

import * as signalR from '@aspnet/signalr';
import {SIGNAL_R_URL} from "../../../core/constants/urls";
import HEADERS from "../../../core/constants/headers";
import ApiHandler from "../../../core/classes/ApiHandler";
import {rxSetFinishedMaintenance} from '../../../state-management/actions/maintenancesActions';


class SignalROperations extends Operations {

    createConnection() {
        const signalRUrl = `${SIGNAL_R_URL}/hiloHub?partnerId=1&${HEADERS.TimeZone}=${HEADERS.TimeZone}${ApiHandler.token ? `&access_token=${
            ApiHandler.token
        }` : ''}`;
        return new signalR.HubConnectionBuilder()
            .withUrl(signalRUrl)
            .build();
    }

    maintenanceFinished(store, data) {
        store.dispatch(rxSetFinishedMaintenance(false))
    }
}

const signalROperations = new SignalROperations();
export default signalROperations;
