import Enum from './EnumCreator';
import { betTypeEnumData } from './util';

export const BET_RESULT_ENUM = Enum('Win', 'Lose', 'Pending', 'Postponed', 'Canceled')(0);
export const CARD_TYPE_ENUM = Enum('Joker',
  'Red_2',
  'Black_2',
  'Red_3',
  'Black_3',
  'Red_4',
  'Black_4',
  'Red_5',
  'Black_5',
  'Red_6',
  'Black_6',
  'Red_7',
  'Black_7',
  'Red_8',
  'Black_8',
  'Red_9',
  'Black_9',
  'Red_J',
  'Black_J',
  'Red_Q',
  'Black_Q',
  'Red_K',
  'Black_K',
  'Red_A',
  'Black_A')(
  0);
export const BET_TYPE_TYPE = Enum('Red_2',
  'Black_2',
  'Number_2',
  'Hi_2',
  'Red_3',
  'Black_3',
  'Number_3',
  'Hi_3',
  'Lo_3',
  'Red_4',
  'Black_4',
  'Number_4',
  'Hi_4',
  'Lo_4',
  'Red_5',
  'Black_5',
  'Number_5',
  'Hi_5',
  'Lo_5',
  'Red_6',
  'Black_6',
  'Number_6',
  'Hi_6',
  'Lo_6',
  'Red_7',
  'Black_7',
  'Number_7',
  'Hi_7',
  'Lo_7',
  'Red_8',
  'Black_8',
  'Number_8',
  'Hi_8',
  'Lo_8',
  'Red_9',
  'Black_9',
  'Number_9',
  'Hi_9',
  'Lo_9',
  'Red_J',
  'Black_J',
  'J',
  'Hi_J',
  'Lo_J',
  'Red_Q',
  'Black_Q',
  'Q',
  'Hi_Q',
  'Lo_Q',
  'Red_K',
  'Black_K',
  'K',
  'Hi_K',
  'Lo_K',
  'Red_A',
  'Black_A',
  'A',
  'Lo_A',
  'Red',
  'Black',
  'Numbers_2_to_9',
  'Joker',
  'J_Q_K_A',
  'J_Q',
  'K_A')(
  0);
export const BET_REPORT_ENUM = Enum(...betTypeEnumData)(0);
export const FORM_TYPES_ENUM = Enum('Text', 'Number', 'Date', 'Radio', 'Checkbox', 'Time', 'Dropdown')(0);
export const WIN_TYPE_ENUM = Enum('For Bet', 'For Win', 'For Lose')(0);
export const TOURNAMENT_STATUS_ENUM = Enum('CANCELED', 'WEB_ACTIVE', 'FINISHED', 'PENDING', 'IN_PROCESS', 'STOPPED')(0);
export const TOURNAMENT_STATUS_CLASS_NAME = Enum('tournament-status-canceled',
  'tournament-status-finished',
  'tournament-status-finishedAndInActive',
  'tournament-status-pending',
  'tournament-status-live',
  'tournament-status-stopped')(
  0);
export const FREE_BET_STATUS_ENUM = Enum('NOT_STARTED', 'ACTIVE', 'EXPIRED', 'CANCELED')(0);
export const FREE_BET_STATUS_CLASS_NAME = Enum('free-bet-pending', 'free-bet-active', 'free-bet-expired', 'free-bet-canceled')(0);
export const FREE_BET_WIN_TYPE_ENUM = Enum('WIN', 'WIN+BET')(0);
export const USER_ROLE = Enum('Admin', 'Player', 'BackOfficeAccount', 'Manager')(0);

export const GAMES_IDS = {
  334: 'Hilo',
};
