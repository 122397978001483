import { combineReducers } from 'redux';
import createAsyncReducer from '../../core/helpers/createAsyncReducer';
import { USER_TOURNAMENT_TYPES } from '../../core/constants/actionTypes';

export const userTournamentsReducer = combineReducers({
  userTournamentsReport: createAsyncReducer(USER_TOURNAMENT_TYPES.RX_GET_USER_TOURNAMENTS_REPORT, {}),
  userTournamentsRangeReport: createAsyncReducer(USER_TOURNAMENT_TYPES.RX_GET_USER_TOURNAMENTS_RANGE_REPORT , {})
});

export default userTournamentsReducer;
