import {LANGUAGES} from "../../core/constants/actionTypes";

export const cntrlGetTranslations = payload => ({
    type : LANGUAGES.CNTRL_GET_TRANSLATIONS,
    payload
});


export const rxGetTranslations = payload => ({
        type : LANGUAGES.RX_GET_TRANSLATIONS,
    payload
});

export const rxGetTranslationsPending = payload => ({
        type : LANGUAGES.RX_GET_TRANSLATIONS_PENDING,
        payload
});

export const rxGetTranslationsDone = payload => ({
        type : LANGUAGES.RX_GET_TRANSLATIONS_DONE,
        payload
});

export const cntrlGetLanguages = payload => ({
    type : LANGUAGES.CNTRL_GET_LANGUAGES,
    payload
});

export const rxGetLanguages = payload => ({
    type : LANGUAGES.RX_GET_LANGUAGES,
    payload
});

export const rxGetLanguagesPending = payload => ({
    type : LANGUAGES.RX_GET_LANGUAGES_PENDING,
    payload
});

export const rxGetLanguagesDone = payload => ({
    type : LANGUAGES.RX_GET_LANGUAGES_DONE,
    payload
});