import Operations from "../../../core/classes/AppOperation";
import responseErrorCheck from "../../../core/helpers/responseErrorChecker";

class ContestTypesOperation extends Operations{
    constructor(contestTypesApiHandler){
        super();
        this.contestTypesApiHandler = contestTypesApiHandler;
    }

   async getContestTypes(query){
        const response = await this.contestTypesApiHandler.getContestTypes(query);
       return responseErrorCheck(response)
   };

   async addContestType(data){
        const response = await this.contestTypesApiHandler.addContestType(data);
       return responseErrorCheck(response)
   };

   async editContestType(data){
       const response = await this.contestTypesApiHandler.editContestType(data);
       return responseErrorCheck(response)
   }
   async deletePartnersFromContestType(data){
       const response = await this.contestTypesApiHandler.deletePartnersFromContestType(data);
       return responseErrorCheck(response)
   }
}
export default ContestTypesOperation