import { AUTH_TYPES } from '../../core/constants/actionTypes';

export const rxSetIsAuth = payload => ({
  type: AUTH_TYPES.RX_SET_AUTH_BOOL,
  payload,
});

export const cntrlAunthenticate = payload => ({
  type: AUTH_TYPES.CNTRL_LOG_IN,
  payload
});

export const cntrlLogOut = payload => ({
  type: AUTH_TYPES.CNTRL_LOGOUT,
});

export const cntrlRefreshToken = () => ({
  type : AUTH_TYPES.CNTRL_REFRESH_TOKEN,
});