    import Controller from "../../core/classes/AppController";
import {
    rxGetPartnersDone,
    rxGetPartnersPending,
    rxGetAllPartnersDone,
    rxGetAllPartnersPending,
    rxGetPartnersMainLimitDone,
    rxGetPartnersMainLimitPending,
    rxGetPartnersBetTypeConfigsPending,
    rxGetPartnersBetTypeConfigsDone,
    rxGetPartnerCurrenciesPending,
    rxGetPartnerCurrenciesDone,
    rxGetPartnersBetTypeLimitConfigsPending,
    rxGetPartnersBetTypeLimitConfigsDone,
    rxGetPartnerContestTypePending,
    rxGetPartnerContestTypeDone,
    rxGetPartnerSelectedCurrenciesPending,
    rxGetPartnerSelectedCurrenciesDone,
    rxAddPartnerPending,
    rxAddPartnerDone,
    rxGetPartnersContestTypesConfigDone,
    rxGetPartnersContestTypesConfigPending, rxPartnerStatusPending, rxPartnerStatusDone,


} from "../../state-management/actions/partnersAction"
    import responseErrorCheck from '../../core/helpers/responseErrorChecker';

class PartnersController extends Controller{
    constructor(partnersOperation){
        super();
        this.partnersOperation = partnersOperation;
    }

    getPartners = async (store , action ) => {
        try {
            store.dispatch(rxGetPartnersPending());
            const response = await this.partnersOperation.getPartners(action.payload);
            store.dispatch(rxGetPartnersDone(response));
        }catch (e) {
            return new Error(e);
        }
    };
    getAllPartners = async (store , action ) => {
        try {
            store.dispatch(rxGetAllPartnersPending());
            const response = await this.partnersOperation.getAllPartners(action.payload);
            store.dispatch(rxGetAllPartnersDone(response));
        }catch (e) {
            return new Error(e);
        }
    };
    getPartnersMainLimit = async (store, action) => {
        try {
            store.dispatch(rxGetPartnersMainLimitPending());
            const response = await this.partnersOperation.getPartnersMainLimit(action.payload);
            store.dispatch(rxGetPartnersMainLimitDone(response))
        } catch (e) {
            return new Error(e)
        }
    }

    getPartnersBetTypeConfigs = async (store, action) => {
        try {
            store.dispatch(rxGetPartnersBetTypeConfigsPending());
            const response = await this.partnersOperation.getPartnersBetTypeConfigs(action.payload);
            store.dispatch(rxGetPartnersBetTypeConfigsDone(response))
        } catch (e) {
            return new Error(e)
        }
    };

    getCurrencies = async (store, action) => {

        try {
            store.dispatch(rxGetPartnerCurrenciesPending());
            const response = await this.partnersOperation.getPartnersCurrencies(action.payload);
            store.dispatch(rxGetPartnerCurrenciesDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    getPartnersBetTypeLimitConfigs = async (store, action) => {
        try {
            store.dispatch(rxGetPartnersBetTypeLimitConfigsPending());
            const response = await this.partnersOperation.getPartnersBetTypeLimitConfigs(action.payload);
            store.dispatch(rxGetPartnersBetTypeLimitConfigsDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    getPartnerContestType = async (store, action) => {
        try {
            store.dispatch(rxGetPartnerContestTypePending());
            const response = await this.partnersOperation.getPartnerContestType(action.payload);
            store.dispatch(rxGetPartnerContestTypeDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    getPartnerSelectedCurrencies = async (store, action) => {
        try {
            store.dispatch(rxGetPartnerSelectedCurrenciesPending());
            const response = await this.partnersOperation.getPartnerSelectedCurrencies(action.payload);
            store.dispatch(rxGetPartnerSelectedCurrenciesDone(response));
        } catch (e) {
            return new Error(e);
        }
    };

    editPartnerMainLimits = async (store, action) => {
        const response = await this.partnersOperation.editPartnerMainLimits(action.payload);
    };

    editPartnerMarkets = async (store, action) => {
        const response = await this.partnersOperation.editPartnerMarkets(action.payload);
    };

    //edit partners
     setPartnerStatus = async (store, action)  => {
         store.dispatch(rxPartnerStatusPending());
         const response = await this.partnersOperation.setPartnerStatus(action.payload);
         store.dispatch(rxPartnerStatusDone(response));
    };

    // add Partner controller
    addPartner = async (store, action) => {
        store.dispatch(rxAddPartnerPending());
        const response = await this.partnersOperation.addPartner(action.payload);
        store.dispatch(rxAddPartnerDone(response));
        return response
    }

    getPartnerContestTypeConfig = async (store,action) =>{
        store.dispatch(rxGetPartnersContestTypesConfigPending());
        const response = await this.partnersOperation.getPartnerContestTypeConfig(action.payload);
        store.dispatch(rxGetPartnersContestTypesConfigDone(response));
        return response
    }

    editPartnerDemo = async (store, action) => {
      const response = await this.partnersOperation.editPartnerDemo(action.payload);
      return response;
    };

}

export default PartnersController
