import {PARTNERS} from "../../core/constants/actionTypes";

// !get Partners

export const cntrlGetPartners = payload => ({
    type : PARTNERS.CNTRL_GET_PARTNERS,
    payload
});

export const rxGetPartners = payload => ({
    type : PARTNERS.RX_GET_PARTNERS,
    payload
});

export const rxGetPartnersPending = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_PENDING,
    payload
});

export const rxGetPartnersDone = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_DONE,
    payload
});
//ALL
export const cntrlGetAllPartners = payload => ({
    type : PARTNERS.CNTRL_GET_ALL_PARTNERS,
    payload
});

export const rxGetAllPartnersPending = payload => ({
    type : PARTNERS.RX_GET_ALL_PARTNERS_PENDING,
    payload
});

export const rxGetAllPartnersDone = payload => ({
       type: PARTNERS.RX_GET_ALL_PARTNERS_DONE,
       payload
});

//!get partners main limit

export const cntrlGetPartnersMainLimit = payload => ({
    type : PARTNERS.CNTRL_GET_PARTNERS_MAIN_LIMIT,
    payload
});

export const rxGetPartnersMainLimit = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_MAIN_LIMIT,
    payload
});

export const rxGetPartnersMainLimitPending = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_MAIN_LIMIT_PENDING,
    payload
});

export const rxGetPartnersMainLimitDone = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_MAIN_LIMIT_DONE,
    payload
});

//!get partners bet type configs

export const cntrlGetPartnersBetTypeConfigs = payload => ({
    type : PARTNERS.CNTRL_GET_PARTNERS_BET_TYPE_CONFIGS,
    payload
});

export const rxGetPartnersBetTypeConfigs = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_BET_TYPE_CONFIGS,
    payload
});

export const rxGetPartnersBetTypeConfigsPending = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_BET_TYPE_CONFIGS_PENDING,
    payload
});

export const rxGetPartnersBetTypeConfigsDone = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_BET_TYPE_CONFIGS_DONE,
    payload
});

export const cntrlGetPartnerCurrencies = payload => ({
   type : PARTNERS.CNTRL_GET_PARTNERS_CURRENCIES,
    payload
});


export const rxGetPartnerCurrencies = payload => ({
   type : PARTNERS.RX_GET_PARTNERS_CURRENCIES,
    payload
});

export const rxGetPartnerCurrenciesPending = payload => ({
   type : PARTNERS.RX_GET_PARTNERS_CURRENCIES_PENDING,
    payload
});

export const rxGetPartnerCurrenciesDone = payload => ({
   type : PARTNERS.RX_GET_PARTNERS_CURRENCIES_DONE,
    payload
});

export const rxSetPartnerCurrency = payload => ({
    type : PARTNERS.RX_SET_PARTNER_CURRENCY,
    payload
});

//Limit configs
export const cntrlGetPartnersBetTypeLimitConfigs = payload => ({
    type : PARTNERS.CNTRL_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS,
    payload
});
export const rxGetPartnersBetTypeLimitConfigsPending = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS_PENDING,
    payload
});
export const rxGetPartnersBetTypeLimitConfigsDone = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS_DONE,
    payload
});


//Configs types
export const cntrlGetPartnerContestType = payload => ({
    type : PARTNERS.CNTRL_GET_PARTNERS_CONTEST_TYPE,
    payload
});
export const rxGetPartnerContestTypePending = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_CONTEST_TYPE_PENDING,
    payload
});
export const rxGetPartnerContestTypeDone = payload => ({
    type : PARTNERS.RX_GET_PARTNERS_CONTEST_TYPE_DONE,
    payload
});

//selected currencies
export const cntrlGetPartnerSelectedCurrencies = payload => ({
    type : PARTNERS.CNTRL_GET_SELECTED_PARTNERS_CURRENCIES,
    payload
});
export const rxGetPartnerSelectedCurrenciesPending = payload => ({
    type : PARTNERS.RX_GET_SELECTED_PARTNERS_CURRENCIES_PENDING,
    payload
});
export const rxGetPartnerSelectedCurrenciesDone = payload => ({
    type : PARTNERS.RX_GET_SELECTED_PARTNERS_CURRENCIES_DONE,
    payload
});

export const cntrlEditPartnerLimits = payload => ({
    type : PARTNERS.CNTRL_EDIT_PARTNER_MAIN_LIMITS,
    payload
});

export const cntrlEditPartnerMarcets = payload => ({
   type : PARTNERS.CNTRL_EDIT_PARTNER_MARCETS,
    payload
});


// add partners

export const cntrlAddPartner = payload =>({
    type:PARTNERS.CNTRL_ADD_PARTNERS,
    payload,
});
export const rxAddPartner = payload =>({
    type:PARTNERS.RX_ADD_PARTNERS,
    payload,
});
export const rxAddPartnerPending = payload =>({
    type:PARTNERS.RX_ADD_PARTNERS_PENDING,
    payload,
});
export const rxAddPartnerDone = payload =>({
    type:PARTNERS.RX_ADD_PARTNERS_DONE,
    payload,
});

// get partnerContestTypeConfig

export const cntrlGetPartnersContestTypesConfig = payload =>({
    type:PARTNERS.CNTRL_GET_PARTNERS_CONTEST_TYPES_CONFIG,
    payload,
});
export const rxGetPartnersContestTypesConfig = payload =>({
    type:PARTNERS.RX_GET_PARTNERS_CONTEST_TYPES_CONFIG,
    payload,
});
export const rxGetPartnersContestTypesConfigDone = payload =>({
    type:PARTNERS.RX_GET_PARTNERS_CONTEST_TYPES_CONFIG_DONE,
    payload,
});
export const rxGetPartnersContestTypesConfigPending = payload =>({
    type:PARTNERS.RX_GET_PARTNERS_CONTEST_TYPES_CONFIG_PENDING,
    payload,
});


//partner edit  status
export const cntrlPartnerStatus = payload => ({
    type:PARTNERS.CNTRL_EDIT_GET_PARTNERS_STATUS,
    payload,
});

export const rxPartnerStatus = payload => ({
    type:PARTNERS.RX_EDIT_GET_PARTNERS_STATUS,
    payload,
});
export const rxPartnerStatusPending = payload => ({
    type:PARTNERS.RX_EDIT_GET_PARTNERS_STATUS_PENDING,
    payload,
});

export const rxPartnerStatusDone = payload => ({
    type:PARTNERS.RX_EDIT_GET_PARTNERS_STATUS_DONE,
    payload,
});

export const cntrlEditPartnerDemo = payload => ({
  type:PARTNERS.CNTRL_EDIT_PARTNER_DEMO,
  payload,
});


