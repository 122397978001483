/**
 * @name Controller
 * @desc this is an abstraction of operation services
 */

class Controller {

  constructor() {}
}

export default Controller;