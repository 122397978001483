import { USER_TOURNAMENT_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetUserTournamentsReport = payload => ({
  type : USER_TOURNAMENT_TYPES.CNTRL_GET_USER_TOURNAMENTS_REPORT,
  payload
});

export const rxGetUserTournamentsReportPending = payload => ({
  type : USER_TOURNAMENT_TYPES.RX_GET_USER_TOURNAMENTS_REPORT_PENDING,
  payload
});

export const rxGetUserTournamentsReportDone = payload => ({
  type : USER_TOURNAMENT_TYPES.RX_GET_USER_TOURNAMENTS_REPORT_DONE,
  payload
});

export const cntrlGetUserTournamentsRangeReport = payload => ({
  type : USER_TOURNAMENT_TYPES.CNTRL_GET_USER_TOURNAMENTS_RANGE_REPORT,
  payload
});

export const rxGetUserTournamentsRangeReportPending = payload => ({
  type : USER_TOURNAMENT_TYPES.RX_GET_USER_TOURNAMENTS_RANGE_REPORT_PENDING,
  payload
});

export const rxGetUserTournamentsRangeReportDone = payload => ({
  type : USER_TOURNAMENT_TYPES.RX_GET_USER_TOURNAMENTS_RANGE_REPORT_DONE,
  payload
});
