import HiLoBackofficeApiHandler from "../HiLoBackofficeApiHandler";

const END_POINT ={
    prefix:'ContestTypes',
    contestTypes : 'contestTypes',
    contestTypePartners:'contestTypePartners',
};

class ContestTypesApiHandler extends HiLoBackofficeApiHandler{
    getContestTypes(query){
        return super.get('',query)
    }

    addContestType(data){
        return super.post('',data)
    }

    editContestType(data){
        return super.post(END_POINT.contestTypes,data)
    }

    deletePartnersFromContestType(data){
        const {partnerIds,contestTypeId} = data;
        return super.delete(END_POINT.contestTypePartners,{contestTypeId},partnerIds)
    }
}
const contestTypesApiHandler = new ContestTypesApiHandler(END_POINT.prefix);
export default contestTypesApiHandler