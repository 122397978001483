import React, { useEffect, useState } from 'react';

import { Popover } from 'bet-core-ui';

import useDebounce from '../../../hooks/useDebounce';
import useHover from '../../../hooks/useHover';


const PopoverWithHover = ({children, childRenOpen, setChildrenOpen, ...restProps}) => {
    // const [hoverRef, hovered] = useHover();
    // const debouncedHover = useDebounce(hovered, hovered ? 0 : 150);

    const [isOpen , setIsOpen] = useState(false);

    useEffect(()=> {
        if(childRenOpen === true) {
            setIsOpen(true)
        } else if (childRenOpen === false) {
            setIsOpen(false)
        }
    }, [childRenOpen]);

    return (
        <div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
            <Popover
                // ref={ref => hoverRef(ref && ref.target)}
                // contentRef={hoverRef}
                isOpen={isOpen}
                {...restProps}
            >
                {children}
            </Popover>
        </div>

    );
};

export default PopoverWithHover;
