import React, { useState , useEffect } from 'react';
import {  ExtendedInput, Paper, Button, Alert ,Icon  } from 'bet-core-ui';
import { connect } from 'react-redux';
import './index.css';
import { cntrlAunthenticate, rxSetIsAuth } from '../../../state-management/actions/tokenActions';
import { cntrlAppInit } from '../../../state-management/actions/appActions';
import {cntrlGetPartnerCurrencies} from "../../../state-management/actions/partnersAction";
import { cntrlGetUser } from "../../../state-management/actions/usersActions";

const Login = ({login, getLanguages , getTranslations ,getCurrencies ,     ...props}) => {
  const [inputValues, setInputValues] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState(false);


  const handleChange = e => {
    const {name, value} = e.target;
    setInputValues({...inputValues, [name]: value});
  };

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const {username, password} = inputValues;
    login({
      username : username.trim(),
      password:password.trim()
    }).then(res => {
      if (!(res.hasOwnProperty('error'))) {
        const clientId = JSON.parse(atob(res.accessToken.split('.')[1])).sub;

        props.getUser().then( () => {
        	props.setIsAuth(true)
        }
      );
        return clientId
      }else{
        setError(true)
      }
    })
  };

  return (
    <>

      <div className={'login-wrapper'}>
        <div className="login-form-holder">
          {
            error ? <Alert title={'ERROR'} message={'WRONG USERNAME OR PASSWORD'} type={'error'}/> : null
          }
          <ExtendedInput
              placeholder="Username"
              inputSize="big"
              appearance="minimal"
              name='username'
              labelAppearance="title"
              onChange={handleChange}

          />
          <ExtendedInput
              type="password"
              placeholder="Password"
              inputSize="big"
              name={'password'}
              appearance="minimal"
              labelAppearance="title"
              onChange={handleChange}

          />
          <Button
              size="big"
              icon="bc-icon-login"
              onClick={handleSubmit}
          >
            Log In
          </Button>
        </div>
      </div>
    </>
  )
};

const mapDispatchToProps = dispatch => ({
  getUser : () => dispatch(cntrlGetUser()),
  login : data => dispatch(cntrlAunthenticate(data)),
  setIsAuth : (bool) => dispatch(rxSetIsAuth(bool)),
  init : () => dispatch(cntrlAppInit()),
  getCurrencies : data => dispatch(cntrlGetPartnerCurrencies())

});

export default connect(null, mapDispatchToProps)(Login);
