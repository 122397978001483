import { FREE_BET_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetFreeBets = payload => ({
    type: FREE_BET_TYPES.CNTRL_GET_FREE_BETS,
    payload,
});

export const rxGetFreeBetsPending = payload => ({
    type: FREE_BET_TYPES.RX_GET_FREE_BETS_PENDING,
    payload,
});

export const rxGetFreeBetsDone = payload => ({
    type: FREE_BET_TYPES.RX_GET_FREE_BETS_DONE,
    payload,
});

//singleFreeBet
export const cntrlGetFreeBet = payload => ({
    type: FREE_BET_TYPES.CNTRL_GET_FREE_BET,
    payload,
});

export const rxGetFreeBetPending = payload => ({
    type: FREE_BET_TYPES.RX_GET_FREE_BET_PENDING,
    payload,
});

export const rxGetFreeBetDone = payload => ({
    type: FREE_BET_TYPES.RX_GET_FREE_BET_DONE,
    payload,
});

export const cntrlGetFreeBetReport = payload => ({
    type: FREE_BET_TYPES.CNTRL_GET_FREE_BET_REPORT,
    payload,
});

export const rxGetFreeBetReportPending = payload => ({
    type: FREE_BET_TYPES.RX_GET_FREE_BET_REPORT_PENDING,
    payload,
});

export const rxGetFreeBetReportDone = payload => ({
    type: FREE_BET_TYPES.RX_GET_FREE_BET_REPORT_DONE,
    payload,
});

export const cntrlAddFreeBets = payload => ({
    type: FREE_BET_TYPES.CNTRL_ADD_FREE_BET,
    payload,
});

export const cntrlEditFreeBets = payload => ({
    type: FREE_BET_TYPES.CNTRL_EDIT_FREE_BET,
    payload,
});

export const cntrlCancelFreeBet = payload => ({
    type: FREE_BET_TYPES.CNTRL_CANCEL_FREE_BET,
    payload,
});
