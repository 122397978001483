import API_METHODS from '../constants/apiMethods';
import HEADERS from '../constants/headers';
import { BEARER } from '../constants/util';
import JSONHelper from '../helpers/JSONhelper';
import store from '../../state-management/store';
import {cntrlRefreshToken} from "../../state-management/actions/tokenActions";

/**
 * This is an abstract class API Handler
 * this class handles API calls
 * this class is a wrapper for all api methods
 */

class ApiHandler {

  // -- Static properties -- //

  static token = '';

  // -- Constructor -- //

  constructor(domain, prefix, token = '', headers = {}) {
    this._domain = domain;
    this._prefix = prefix;
    this._token = token;
    this.headers= headers;
  }

  // -- Logic -- //

  /**
   * @name get
   * @desc global get request
   * @param url
   * @param options
   * @returns {Promise<Response>}
   */
  get(url = '') {
    return fetch(url, {
      method: API_METHODS.GET,
      headers: this._createHeaders()
    }).then(res => {
      return this._errorAndSuccessFilter(res)
    });
  }

  /**
   * @name post
   * @desc global post request
   * @param url
   * @param body
   * @returns {Promise<Response>}
   */
  post(url = '', body) {
    return fetch(url, {
      method: API_METHODS.POST,
      headers: this._createHeaders(),
      body: JSONHelper.stringify(body)
    }).then(res =>this._errorAndSuccessFilter(res));
  }

  /**
   * @name put
   * @desc global put request
   * @param url
   * @param body
   * @returns {Promise<any>}
   */
  put(url = '', body , isFormData  ) {
    return fetch(url, {
      method: API_METHODS.PUT,
      headers: this._createHeaders(isFormData),
      body: isFormData ? body :  JSONHelper.stringify(body)
    }).then(res => this._errorAndSuccessFilter(res));
  }

  /**
   * @name delete
   * @desc global delete request
   * @param url
   * @param body
   * @returns {Promise<any>}
   */
  delete(url = '' , body) {
    return fetch(url, {
      method: API_METHODS.DELETE,
      headers: this._createHeaders(),
      body : JSONHelper.stringify(body)
    }).then(res => this._errorAndSuccessFilter(res));
  }

  /**
   * @name _createHeaders
   * @desc factory for creating headers
   * @return { * }
   * @private
   */
  _createHeaders = (isFormdata) => {
    let headers = {
      [HEADERS.Accept]: HEADERS.ApplicationJson,
      [HEADERS.TimeZoneKey]: HEADERS.TimeZone,
      ...this.headers,
    };
    if (ApiHandler.token) {
      headers = {
        [HEADERS.Authorization]: `${BEARER} ${ApiHandler.token}`,
        ...headers
      };
    }
    if (!isFormdata) {
      headers = {
        [HEADERS.ContentType]: HEADERS.ApplicationJson,
        ...headers
      };
    }

    return headers;
  };

  _errorAndSuccessFilter(res) {
    if(res.status === 401){
      store.dispatch(cntrlRefreshToken());
      return null;
    }
    if(res.ok) {
      return res.json()
    } else {
      return {
        status: 0,
        responseStatus: res.status,
        errorMessage: res.statusText,
      }
    }
  }

  // -- Getters / Setters -- //


  get domain() {
    return this._domain;
  }

  set domain(value) {
    this._domain = value;
  }

  get preifx() {
    return this._preifx;
  }

  set preifx(value) {
    this._preifx = value;
  }

  get token() {
    return this._token;
  }

  set token(value) {
    this._token = value;
  }

  get prefix() {
    return this._prefix;
  }

  set prefix(value) {
    this._prefix = value;
  }

}


export default ApiHandler;
