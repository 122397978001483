import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';



class IsAuthLayer extends React.PureComponent {

  render() {

    const { isAuth, children } = this.props;

    return (
      <>
        {isAuth ? children : <Redirect to={'/login'}/>}
      </>
    )
  }
}



IsAuthLayer.propTypes = {
  isAuth : PropTypes.bool,
};

const mapStateToProps = state => ({
  isAuth : state.token.isAuthReducer
});

export default withRouter(connect(mapStateToProps, null)(IsAuthLayer));

