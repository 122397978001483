import AppNavigationItemCreator from '../classes/AppNavigationItemCreator';

export const navigationConstants = [
    {
        text: 'DASHBOARD',
        to: '/dashboard',
        roles: ['Admin', 'BackOfficeAccount']
    },
    {
        text: 'PARTNERS',
        to: '/partners',
        roles: ['Admin'],
        subSections: [
            {
                text: 'PARTNERS_SETTINGS',
                to: '/partners-settings',
                roles: ['Admin']
            },
            {
                text: 'FINANCIAL_REPORT',
                to: '/financial-report',
                roles: ['Admin']
            },
            {
                text: 'CONTEST_TYPES',
                to: '/contest_types',
                roles: ['Admin']
            }
        ]
    },
    {
        text: 'REPORTS',
        to: '/reports',
        roles: ['Admin', 'Manager', 'BackOfficeAccount'],
        subSections: [
            {
                text: 'BET_REPORTS',
                to: '/bet-reports',
                roles: ['Admin', 'Manager', 'BackOfficeAccount']
            },
            {
                text: 'USER_REPORT',
                to: '/user-report',
                roles: ['Admin', 'Manager', 'BackOfficeAccount']
            },
            {
                text: 'CONTEST_REPORT',
                to: '/contest-report',
                roles: ['Admin']
            },
            {
                text: 'MARKET_REPORT',
                to: '/market-report',
                roles: ['Admin']
            },
            {
                text: 'BONUS_REPORT',
                to: '/bonus-reports',
                roles: ['Admin', 'Manager', 'BackOfficeAccount'],
                showInner: true,
                subSections: [
                    {
                        text: 'FREE_BET',
                        to: '/free-bet',
                        roles: ['Admin', 'Manager', 'BackOfficeAccount']
                    },
                    {
                        text: 'MASTER',
                        to: '/master',
                        roles: ['Admin', 'Manager', 'BackOfficeAccount'],
                    },
                ]
            },
        ]
    },
    {
        text: 'BONUS',
        to: '/bonus',
        roles: ['Admin', 'BackOfficeAccount'],
        subSections: [
            {
                text: 'HILO_MASTER',
                to: '/hilo_master',
                roles: ['Admin', 'BackOfficeAccount'],
                subSections: [
                    {
                        text: 'HILO_MASTER',
                        to: '/add',
                        roles: ['Admin'],
                    },
                    {
                        text: 'HILO_MASTER',
                        to: '/edit',
                        roles: ['Admin'],
                    },
                    {
                        text: 'MASTER_LIST',
                        to: '/view',
                        roles: ['Admin', 'BackOfficeAccount'],
                    },
                    {
                        text: 'HILO_MASTER',
                        to: '/clone',
                        roles: ['Admin'],
                    }
                ]
            },
            {
                text: 'MASTER_LIST',
                to: '/master_list',
                roles: ['Admin', 'BackOfficeAccount'],
                subSections: [
                    {
                        text: 'MASTER_LIST',
                        to: '/view',
                        roles: ['Admin'],
                    },
                ]
            },
            {
                text: 'FREE BET',
                to: '/freebet',
                roles: ['Admin'],
                subSections: [
                    {
                        text: 'FREE BET',
                        to: '/add',
                        roles: ['Admin'],
                    },
                    {
                        text: 'FREE BET',
                        to: '/edit',
                        roles: ['Admin'],
                    },
                    {
                        text: 'FREE BET',
                        to: '/clone',
                        roles: ['Admin'],
                    }
                ]
            }
        ]
    },
];
