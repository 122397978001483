import HiLoBackofficeApiHandler from "../HiLoBackofficeApiHandler";


const END_POINT = {
    prefix : 'Reports',
    betReport : 'betReport',
    userReport : 'userReport',
    dashboard : 'dashboard',
    topBets : 'topBets',
    contestReport : 'contestReport',
    financial : 'financial'
};
class ReportsApiHandler extends HiLoBackofficeApiHandler{
    getBetReport(query){
        return super.get(END_POINT.betReport , query)
    }
    getFinancialReport(query){
        return super.get(END_POINT.financial , query)
    }

    getUserReport(query){
        return super.get(END_POINT.userReport , query)
    }

    getDashboardReport(query){
        return super.get(END_POINT.dashboard , query)
    }

    getTopBetReport(query){
        return super.get(END_POINT.topBets , query);
    }

    getContestReport(query){
        return super.get(END_POINT.contestReport , query)
    }
    getTopWinBets(query){
        return super.get(END_POINT.topBets , {...query , Result : 'WIN' })
    }

    getLoseBetReport(query){
        return super.get(END_POINT.topBets , {...query , Result : 'LOSE' , OrderColumnName : 'StakeAmount'})
    }

    getMarketReport(query){
        return super.get(`betTypes` , query)
    }
}

const reportsApiHandler = new ReportsApiHandler(END_POINT.prefix);
export default reportsApiHandler;
