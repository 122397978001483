import { TOURNAMENT_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetTournaments = payload => ({
    type: TOURNAMENT_TYPES.CNTRL_GET_TOURNAMENTS,
    payload
});

export const rxGetTournamentsPending = payload => ({
    type: TOURNAMENT_TYPES.RX_GET_TOURNAMENTS_PENDING,
    payload
});

export const rxGetTournamentsDone = payload => ({
    type: TOURNAMENT_TYPES.RX_GET_TOURNAMENTS_DONE,
    payload
});

export const cntrlGetTournament = payload => ({
    type: TOURNAMENT_TYPES.CNTRL_GET_TOURNAMENT,
    payload
});

export const rxGetTournamentPending = payload => ({
    type: TOURNAMENT_TYPES.RX_GET_TOURNAMENT_PENDING,
    payload
});

export const rxGetTournamentDone = payload => ({
    type: TOURNAMENT_TYPES.RX_GET_TOURNAMENT_DONE,
    payload
});

export const cntrlAddTournament = payload => ({
    type: TOURNAMENT_TYPES.CNTRL_ADD_TOURNAMENT,
    payload
});


export const cntrlUpdateTournament = payload => ({
    type: TOURNAMENT_TYPES.CNTRL_UPDATE_TOURNAMENT,
    payload
});

export const cntrlCancelTournament = payload => ({
    type: TOURNAMENT_TYPES.CNTRL_CANCEL_TOURNAMENT,
    payload
});

export const cntrlGetTournamentPlayers = payload => ({
    type: TOURNAMENT_TYPES.CNTRL_GET_TOURNAMENT_PLAYERS,
    payload
});

export const rxGetTournamentPlayersPending = payload => ({
    type: TOURNAMENT_TYPES.RX_GET_TOURNAMENT_PLAYERS_PENDING,
    payload
});

export const rxGetTournamentPlayersDone = payload => ({
    type: TOURNAMENT_TYPES.RX_GET_TOURNAMENT_PLAYERS_DONE,
    payload
});

export const cntrlGetPartnersTournament = payload => ({
    type: TOURNAMENT_TYPES.CNTRL_GET_PARTNERS_TOURNAMENT,
    payload
});

export const rxGetPartnersTournamentPending = payload => ({
    type: TOURNAMENT_TYPES.RX_GET_PARTNERS_TOURNAMENT_PENDING,
    payload
});

export const rxGetPartnersTournamentDone = payload => ({
    type: TOURNAMENT_TYPES.RX_GET_PARTNERS_TOURNAMENT_DONE,
    payload
});
