let DOMAIN = '';//staging
// export const    DOMAIN = 'http://10.25.60.170:5055';//
let TRANSLATION = '';

if (process.env.REACT_APP_ENV === 'production') {
  DOMAIN = 'https://hilo-api.betconstruct.com';
  TRANSLATION = 'https://rpd-translations.betcoapps.com';
  // TRANSLATION = 'https://rpd-translations.bcapps.site'
} else {
  DOMAIN = 'https://hilo-api-stage.betcoapps.com';
  TRANSLATION = 'https://rpd-translations-stage.betcoapps.com';
  // TRANSLATION = 'https://rpd-translations.bcapps.site'
}

export const API = `${DOMAIN}/api`;
// export const PHOTO_URL = 'https://api.fantasybet.co/';
// export const PHOTO_URL = 'http://10.95.6.135:5000/'
export const PHOTO_URL = 'https://api.fantasybet.co/';
export const TRANSLATION_API = `${TRANSLATION}/api/v1`;
export const SIGNAL_R_URL = DOMAIN;
