import { BET_TYPES } from '../../core/constants/actionTypes';

export const cntrlGetBetTypes = payload => ({
    type : BET_TYPES.CNTRL_GET_BET_TYPES,
    payload
});

export const rxGetBetTypesPending = payload => ({
    type : BET_TYPES.RX_GET_BET_TYPES_PENDING,
    payload
});

export const rxGetBetTypesDone = payload => ({
    type : BET_TYPES.RX_GET_BET_TYPES_DONE,
    payload
});
