import Controller from '../../core/classes/AppController';
import {
  rxGetUserTournamentsReportPending,
  rxGetUserTournamentsReportDone,
  rxGetUserTournamentsRangeReportPending,
  rxGetUserTournamentsRangeReportDone
} from '../../state-management/actions/userTournamentsActions';

class UserTournamentsController extends Controller {
  constructor(userTournamentsOperations) {
    super();
    this.userTournamentsOperations = userTournamentsOperations;
  }

  getUserTournamentsReport = async (store, action) => {
    try {
      store.dispatch(rxGetUserTournamentsReportPending());
      const response = await this.userTournamentsOperations.getUserTournamentsReport(action.payload);
      store.dispatch(rxGetUserTournamentsReportDone(response));
    } catch (e) {
      return new Error(e);
    }
  };

  getUserTournamentsRangeReport = async (store, action) => {
    try {
      store.dispatch(rxGetUserTournamentsRangeReportPending());
      const response = await this.userTournamentsOperations.getUserTournamentsRangeReport(action.payload);
      store.dispatch(rxGetUserTournamentsRangeReportDone(response));
    } catch (e) {
      return new Error(e);
    }
  };

}

export default UserTournamentsController;
