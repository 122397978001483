import HiLoTranslationsApiHandler from "../HiLoTranslationsApiHandler";


const END_POINT = {
    prefix : 'Translations',
    languages : 'languages'
};

class LanguagesApiHandler extends HiLoTranslationsApiHandler{


    getTranslationsByCode(code = 'en'){
        return super.get('Code/Game' ,
            {code})
    }

    getLanguages(data){
        this.prefix = 'Languages';
        const response = super.get();
        this.prefix = 'Translations';
        return response
    }
}

const languagesApiHandler = new LanguagesApiHandler(END_POINT.prefix);
export default languagesApiHandler;
