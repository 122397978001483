import Controller from "../../core/classes/AppController";
import {
    rxGetBetReportDone,
    rxGetBetReportPending,
    rxGetContestReportDone,
    rxGetContestReportPending,
    rxGetDashboardReportDone,
    rxGetDashboardReportPending, rxGetLoseBetReportDone,
    rxGetLoseBetReportPending,
    rxGetTopBetsDone,
    rxGetTopBetsPending,
    rxGetTopWinBetsDone,
    rxGetTopWinBetsPending,
    rxGetUserReportDone,
    rxGetUserReportPending,
    rxGetFinancialReportDone,
    rxGetFinancialReportPending, rxGetMarketReportPending, rxGetMarketReportDone

} from "../../state-management/actions/reportsActions";

class ReportsController extends Controller{
    constructor(reportsOperation){
        super();
        this.reportsOperation = reportsOperation;
    }

    getBetReport = async (store  , action ) => {
        try {
            store.dispatch(rxGetBetReportPending());
            const response = await this.reportsOperation.getBetReport(action.payload);
            store.dispatch(rxGetBetReportDone(response));
        }catch (e) {
            return new Error(e);
        }
    };
    getFinancialReport = async (store  , action ) => {
        try {
            store.dispatch(rxGetFinancialReportPending());
            const response = await this.reportsOperation.getFinancialReport(action.payload);
            store.dispatch(rxGetFinancialReportDone(response));
        }catch (e) {
            return new Error(e);
        }
    };

    getUserReport = async (store , action) => {
        try {
            store.dispatch(rxGetUserReportPending());
            const response = await this.reportsOperation.getUserReport(action.payload);
            store.dispatch(rxGetUserReportDone(response));
        }catch (e) {
            return new Error(e);
        }
    };

    getContestReport = async (store , action) => {
        try {
            store.dispatch(rxGetContestReportPending());
            const response = await this.reportsOperation.getContestReport(action.payload);
            store.dispatch(rxGetContestReportDone(response));
        }catch (e) {
            return new Error(e);
        }
    };

    getDashboardReport = async (store , action) => {
        try{
            store.dispatch(rxGetDashboardReportPending());
            const response = await this.reportsOperation.getDashboardReport(action.payload);
            store.dispatch(rxGetDashboardReportDone(response));

        }catch (e) {
            return new Error(e);
        }
    }

    getTopBetReport = async (store , action) => {
        try{
            store.dispatch(rxGetTopBetsPending());
            const response = await this.reportsOperation.getTopBetReport(action.payload);
            store.dispatch(rxGetTopBetsDone(response));

        }catch (e) {
            return new Error(e);
        }
    }

    getTopWinBets = async (store , action) => {
        try {
            store.dispatch(rxGetTopWinBetsPending());
            const response = await this.reportsOperation.getTopWinBets(action.payload);
            store.dispatch(rxGetTopWinBetsDone(response))
        }catch (e) {
            return new Error(e);
        }
    }

    getLoseBetReport = async (store , action) => {
        store.dispatch(rxGetLoseBetReportPending());
        const response = await this.reportsOperation.getLoseBetReport(action.payload);
        store.dispatch(rxGetLoseBetReportDone(response))
    }

    getMarketReport = async (store , action) => {
        store.dispatch(rxGetMarketReportPending());
        const response = await this.reportsOperation.getMarketReport(action.payload);
        store.dispatch(rxGetMarketReportDone(response));
    }
}

export default ReportsController
