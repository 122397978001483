import React, {Suspense} from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import rootRoutes  from './router';
import { BusyLoader } from 'bet-core-ui';
const SuspenseWrap = props => <Suspense fallback={<BusyLoader isBusy type="spinner"/>}>{props.children}</Suspense>;

const DefaultRoutes = props => {

  return<>
    <Switch>
      {
        rootRoutes.map(route => {
          if(route.innerRoutes && route.innerRoutes.length){
            return route.innerRoutes.map(innerRoute => {
              return (
                <Route
                  exact={route.exact}
                  key={`${route.path}${innerRoute.path}`}
                  path={`${route.path}${innerRoute.path}`}
                  render={() => <SuspenseWrap>
                    <innerRoute.component {...props}/>
                  </SuspenseWrap>
                  }
                />
              )
            })
          }
          return(
            <Route
              exact
              key={route.path}
              path={route.path}
              render={() => <Suspense>
                <route.component {...props}/>
              </Suspense>}
            />
          )
        })
      }
      {/* <Redirect exact from='/' to='/dashboard'/> */}
    </Switch>
  </>
};

export default withRouter(DefaultRoutes);
