// *APP INIT TYPES
export const APP_INIT_TYPES = {
    CNTRL_APP_INIT: 'CNTRL_APP_INIT'
};


// *AUTH TYPES
export const AUTH_TYPES = {
    CNTRL_LOG_IN: 'CNTRL_LOG_IN',
    CNTRL_REFRESH_TOKEN: 'CNTRL_REFRESH_TOKEN',
    RX_SET_AUTH_BOOL: 'RX_SET_AUTH_BOOL',
    CNTRL_LOGOUT: 'CNTRL_LOGOUT',
};


// *REPORT TYPES
export const REPORT_TYPES = {

// !DASHBOARD REPORTS
    //FINANCIAL REPORT
    CNTRL_GET_FINANCIAL_REPORT: 'CNTRL_GET_FINANCIAL_REPORT',
    RX_GET_FINANCIAL_REPORT: 'RX_GET_FINANCIAL_REPORT',
    RX_GET_FINANCIAL_REPORT_PENDING: 'RX_GET_FINANCIAL_REPORT_PENDING',
    RX_GET_FINANCIAL_REPORT_DONE: 'RX_GET_FINANCIAL_REPORT_DONE',
    // !BET REPORT
    CNTRL_GET_BET_REPORT: 'CNTRL_GET_BET_REPORT',
    RX_GET_BET_REPORT: 'RX_GET_BET_REPORT',
    RX_GET_BET_REPORT_PENDING: 'RX_GET_BET_REPORT_PENDING',
    RX_GET_BET_REPORT_DONE: 'RX_GET_BET_REPORT_DONE',

    // !USER REPORT
    CNTRL_GET_USER_REPORT: 'CNTRL_GET_USER_REPORT',
    RX_GET_USER_REPORT: 'RX_GET_USER_REPORT',
    RX_GET_USER_REPORT_PENDING: 'RX_GET_USER_REPORT_PENDING',
    RX_GET_USER_REPORT_DONE: 'RX_GET_USER_REPORT_DONE',

    // !CONTEST REPORT
    CNTRL_GET_CONTEST_REPORT: 'CNTRL_GET_CONTEST_REPORT',
    RX_GET_CONTEST_REPORT: 'RX_GET_CONTEST_REPORT',
    RX_GET_CONTEST_REPORT_PENDING: 'RX_GET_CONTEST_REPORT_PENDING',
    RX_GET_CONTEST_REPORT_DONE: 'RX_GET_CONTEST_REPORT_DONE',

    // !DASHBOARD REPORT
    CNTRL_GET_DASHBOARD_REPORT: 'CNTRL_GET_DASHBOARD_REPORT',
    RX_GET_DASHBOARD_REPORT: 'RX_GET_DASHBOARD_REPORT',
    RX_GET_DASHBOARD_REPORT_PENDING: 'RX_GET_DASHBOARD_REPORT_PENDING',
    RX_GET_DASHBOARD_REPORT_DONE: 'RX_GET_DASHBOARD_REPORT_DONE',

    // !TOP BETS
    CNTRL_GET_TOP_BETS: 'CNTRL_GET_TOP_BETS',
    RX_GET_TOP_BETS: 'RX_GET_TOP_BETS',
    RX_GET_TOP_BETS_PENDING: 'RX_GET_TOP_BETS_PENDING',
    RX_GET_TOP_BETS_DONE: 'RX_GET_TOP_BETS_DONE',

    // !TOP WIN BETS
    CNTRL_GET_TOP_WIN_BETS: 'CNTRL_GET_TOP_WIN_BETS',
    RX_GET_TOP_WIN_BETS: 'RX_GET_TOP_WIN_BETS',
    RX_GET_TOP_WIN_BETS_PENDING: 'RX_GET_TOP_WIN_BETS_PENDING',
    RX_GET_TOP_WIN_BETS_DONE: 'RX_GET_TOP_WIN_BETS_DONE',

    // !LOSE BET REPORT
    CNTRL_GET_LOSE_BET_REPORT: 'CNTRL_GET_LOSE_BET_REPORT',
    RX_GET_LOSE_BET_REPORT: 'RX_GET_LOSE_BET_REPORT',
    RX_GET_LOSE_BET_REPORT_PENDING: 'RX_GET_LOSE_BET_REPORT_PENDING',
    RX_GET_LOSE_BET_REPORT_DONE: 'RX_GET_LOSE_BET_REPORT_DONE',

    CNTRL_GET_MARKET_REPORT: 'CNTRL_GET_MARKET_REPORT',
    RX_GET_MARKET_REPORT: 'RX_GET_MARKET_REPORT',
    RX_GET_MARKET_REPORT_PENDING: 'RX_GET_MARKET_REPORT_PENDING',
    RX_GET_MARKET_REPORT_DONE: 'RX_GET_MARKET_REPORT_DONE'


};


// *CONTESTS
export const CONTEST = {

    // !GET CONTESTS
    CNTRL_GET_CONTEST_TYPES: 'CNTRL_GET_CONTESTS_TYPES',
    RX_GET_CONTEST_TYPES: 'RX_GET_CONTESTS_TYPES',
    RX_GET_CONTEST_TYPES_PENDING: 'RX_GET_CONTESTS_TYPES_PENDING',
    RX_GET_CONTEST_TYPES_DONE: 'RX_GET_CONTESTS_TYPES_DONE',

    //ADD CONTEST
    CNTRL_ADD_CONTEST_TYPE: 'CNTRL_ADD_CONTEST_TYPE',
    RX_ADD_CONTEST_TYPE: 'RX_ADD_CONTEST_TYPE',
    RX_ADD_CONTEST_TYPE_DONE: 'RX_ADD_CONTEST_TYPE_DONE',
    RX_ADD_CONTEST_TYPE_PENDING: 'RX_ADD_CONTEST_TYPE_PENDING',

    //EDIT CONTEST
    CNTRL_EDIT_CONTEST_TYPE: 'CNTRL_EDIT_CONTEST_TYPE',
    RX_EDIT_CONTEST_TYPE: 'RX_EDIT_CONTEST_TYPE',
    RX_EDIT_CONTEST_TYPE_DONE: 'RX_EDIT_CONTEST_TYPE_DONE',
    RX_EDIT_CONTEST_TYPE_PENDING: 'RX_EDIT_CONTEST_TYPE_PENDING',

    // !SET CONTEST VERIFICATION
    CNTRL_SET_CONTEST_VERIFICATION: 'CNTRL_SET_CONTEST_VERIFICATION',
    RX_SET_CONTEST_VERIFICATION: 'RX_SET_CONTEST_VERIFICATION',
    RX_SET_CONTEST_VERIFICATION_PENDING: 'RX_SET_CONTEST_VERIFICATION_PENDING',
    RX_SET_CONTEST_VERIFICATION_DONE: 'RX_SET_CONTEST_VERIFICATION_DONE',

    //DELETE PARTNERS FROM CONTEST TYPE
    CNTRL_DELETE_PARTNERS_FROM_CONTEST_TYPE: 'CNTRL_DELETE_PARTNERS_FROM_CONTEST_TYPE',
    RX_DELETE_PARTNERS_FROM_CONTEST_TYPE: 'RX_DELETE_PARTNERS_FROM_CONTEST_TYPE',
    RX_DELETE_PARTNERS_FROM_CONTEST_TYPE_DONE: 'RX_DELETE_PARTNERS_FROM_CONTEST_TYPE_DONE',
    RX_DELETE_PARTNERS_FROM_CONTEST_TYPE_PENDING: 'RX_DELETE_PARTNERS_FROM_CONTEST_TYPE_PENDING',
};

// *LIVE
export const LIVE = {

    // !SET LIVE CHANGES
    CNTRL_SET_LIVE_CHANGES: 'CNTRL_SET_LIVE_CHANGES',
    RX_SET_LIVE_CHANGES: 'RX_SET_LIVE_CHANGES',
    RX_SET_LIVE_CHANGES_PENDIING: 'RX_SET_LIVE_CHANGES_PENDIING',
    RX_SET_LIVE_CHANGES_DONE: 'RX_SET_LIVE_CHANGES_DONE'
};


// *PARTNERS
export const PARTNERS = {

    // !GET PARTNERS
    CNTRL_GET_PARTNERS: 'CNTRL_GET_PARTNERS',
    RX_GET_PARTNERS: 'RX_GET_PARTNERS',
    RX_GET_PARTNERS_PENDING: 'RX_GET_PARTNERS_PENDING',
    RX_GET_PARTNERS_DONE: 'RX_GET_PARTNERS_DONE',

    // !GET ALL_ PARTNERS
    CNTRL_GET_ALL_PARTNERS: 'CNTRL_GET_ALL_PARTNERS',
    RX_GET_ALL_PARTNERS: 'RX_GET_ALL_PARTNERS',
    RX_GET_ALL_PARTNERS_PENDING: 'RX_GET_ALL_PARTNERS_PENDING',
    RX_GET_ALL_PARTNERS_DONE: 'RX_GET_ALL_PARTNERS_DONE',

    // !EDIT PARTNERS
    CNTRL_EDIT_PARTNERS: 'CNTRL_EDIT_PARTNERS',
    RX_EDIT_PARTNERS: 'RX_EDIT_PARTNERS',
    RX_EDIT_PARTNERS_PENDING: 'RX_EDIT_PARTNERS_PENDING',
    RX_EDIT_PARTNERS_DONE: 'RX_EDIT_PARTNERS_DONE',

    // !ADD PARTNERS
    CNTRL_ADD_PARTNERS: 'CNTRL_ADD_PARTNERS',
    RX_ADD_PARTNERS: 'RX_ADD_PARTNERS',
    RX_ADD_PARTNERS_PENDING: 'RX_ADD_PARTNERS_PENDING',
    RX_ADD_PARTNERS_DONE: 'RX_ADD_PARTNERS_DONE',

    // !GET PARTNERS MAIN LIMIT
    CNTRL_GET_PARTNERS_MAIN_LIMIT: 'CNTRL_GET_PARTNERS_MAIN_LIMIT',
    RX_GET_PARTNERS_MAIN_LIMIT: 'RX_GET_PARTNERS_MAIN_LIMIT',
    RX_GET_PARTNERS_MAIN_LIMIT_PENDING: 'RX_GET_PARTNERS_MAIN_LIMIT_PENDING',
    RX_GET_PARTNERS_MAIN_LIMIT_DONE: 'RX_GET_PARTNERS_MAIN_LIMIT_DONE',

    // !GET PARTNERS BET TYPE CONFIGS
    CNTRL_GET_PARTNERS_BET_TYPE_CONFIGS: 'CNTRL_GET_PARTNERS_BET_TYPE_CONFIGS',
    RX_GET_PARTNERS_BET_TYPE_CONFIGS: 'RX_GET_PARTNERS_BET_TYPE_CONFIGS',
    RX_GET_PARTNERS_BET_TYPE_CONFIGS_PENDING: 'RX_GET_PARTNERS_BET_TYPE_CONFIGS_PENDING',
    RX_GET_PARTNERS_BET_TYPE_CONFIGS_DONE: 'RX_GET_PARTNERS_BET_TYPE_CONFIGS_DONE',

    // !GET PARTNERS MIN LIMIT
    CNTRL_GET_PARTNERS_MIN_LIMIT: 'CNTRL_GET_PARTNERS_MIN_LIMIT',
    RX_GET_PARTNERS_MIN_LIMIT: 'RX_GET_PARTNERS_MIN_LIMIT',
    RX_GET_PARTNERS_MIN_LIMIT_PENDING: 'RX_GET_PARTNERS_MIN_LIMIT_PENDING',
    RX_GET_PARTNERS_MIN_LIMIT_DONE: 'RX_GET_PARTNERS_MIN_LIMIT_DONE',

//GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS
    CNTRL_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS: 'CNTRL_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS',
    RX_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS: 'RX_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS',
    RX_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS_PENDING: 'RX_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS_PENDING',
    RX_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS_DONE: 'RX_GET_PARTNERS_BET_TYPE_LIMIT_CONFIGS_DONE',

//GET_PARTNERS_CONTEST_TYPE
    CNTRL_GET_PARTNERS_CONTEST_TYPE: 'CNTRL_GET_PARTNERS_CONTEST_TYPE',
    RX_GET_PARTNERS_CONTEST_TYPE: 'RX_GET_PARTNERS_CONTEST_TYPE',
    RX_GET_PARTNERS_CONTEST_TYPE_PENDING: 'RX_GET_PARTNERS_CONTEST_TYPE_PENDING',
    RX_GET_PARTNERS_CONTEST_TYPE_DONE: 'RX_GET_PARTNERS_CONTEST_TYPE_DONE',


// GET_PARTNERS_CONTEST_TYPES_CONFIG
    CNTRL_GET_PARTNERS_CONTEST_TYPES_CONFIG: 'CNTRL_GET_PARTNERS_CONTEST_TYPES_CONFIG',
    RX_GET_PARTNERS_CONTEST_TYPES_CONFIG: 'RX_GET_PARTNERS_CONTEST_TYPES_CONFIG',
    RX_GET_PARTNERS_CONTEST_TYPES_CONFIG_DONE: 'RX_GET_PARTNERS_CONTEST_TYPES_CONFIG_DONE',
    RX_GET_PARTNERS_CONTEST_TYPES_CONFIG_PENDING: 'RX_GET_PARTNERS_CONTEST_TYPES_CONFIG_PENDING',


//GET_PARTNERS_CURRENCIES
    CNTRL_GET_PARTNERS_CURRENCIES: 'CNTRL_GET_PARTNERS_CURRENCIES',
    RX_GET_PARTNERS_CURRENCIES: 'RX_GET_PARTNERS_CURRENCIES',
    RX_GET_PARTNERS_CURRENCIES_PENDING: 'RX_GET_PARTNERS_CURRENCIES_PENDING',
    RX_GET_PARTNERS_CURRENCIES_DONE: 'RX_GET_PARTNERS_CURRENCIES_DONE',

    RX_SET_PARTNER_CURRENCY: 'RX_SET_PARTNER_CURRENCY',

//GET_SELECTED_PARTNERS_CURRENCIES
    CNTRL_GET_SELECTED_PARTNERS_CURRENCIES: 'CNTRL_GET_SELECTED_PARTNERS_CURRENCIES',
    RX_GET_SELECTED_PARTNERS_CURRENCIES: 'RX_GET_SELECTED_PARTNERS_CURRENCIES',
    RX_GET_SELECTED_PARTNERS_CURRENCIES_PENDING: 'RX_GET_SELECTED_PARTNERS_CURRENCIES_PENDING',
    RX_GET_SELECTED_PARTNERS_CURRENCIES_DONE: 'RX_GET_SELECTED_PARTNERS_CURRENCIES_DONE',

    CNTRL_EDIT_PARTNER_MAIN_LIMITS: 'CNTRL_EDIT_PARTNER_MAIN_LIMITS',
    RX_EDIT_PARTNER_MAIN_LIMITS: 'RX_EDIT_PARTNER_MAIN_LIMITS',
    RX_EDIT_PARTNER_MAIN_LIMITS_PENDING: 'RX_EDIT_PARTNER_MAIN_LIMITS_PENDING',
    RX_EDIT_PARTNER_MAIN_LIMITS_DONE: 'RX_EDIT_PARTNER_MAIN_LIMITS_DONE',

    // GET_PARTNERS_STATUS
    CNTRL_EDIT_GET_PARTNERS_STATUS: 'CNTRL_EDIT_GET_PARTNERS_STATUS',
    RX_EDIT_GET_PARTNERS_STATUS: 'RX_EDIT_GET_PARTNERS_STATUS',
    RX_EDIT_GET_PARTNERS_STATUS_PENDING: 'RX_EDIT_GET_PARTNERS_STATUS_PENDING',
    RX_EDIT_GET_PARTNERS_STATUS_DONE: 'RX_EDIT_GET_PARTNERS_STATUS_DONE',


    CNTRL_EDIT_PARTNER_MARCETS: 'CNTRL_EDIT_PARTNER_MARCETS',

    CNTRL_EDIT_PARTNER_DEMO: 'CNTRL_EDIT_PARTNER_DEMO',

};


// *POINTS
export const POINTS = {
    // !GET POINTS
    CNTRL_GET_POINTS: 'CNTRL_GET_POINTS',
    RX_GET_POINTS: 'RX_GET_POINTS',
    RX_GET_POINTS_PENDING: 'RX_GET_POINTS_PENDING',
    RX_GET_POINTS_DONE: 'RX_GET_POINTS_DONE',

    // !EDIT POINTS
    CNTRL_EDIT_POINTS: 'CNTRL_EDIT_POINTS',
    RX_EDIT_POINTS: 'RX_EDIT_POINTS',
    RX_EDIT_POINTS_PENDING: 'RX_EDIT_POINTS_PENDING',
    RX_EDIT_POINTS_DONE: 'RX_EDIT_POINTS_DONE',

};


// *TOKEN
export const TOKEN = {

    // !GET TOKEN PERMISSION
    CNTRL_GET_TOKEN_PERMISSION: 'CNTRL_GET_TOKEN_PERMISSION',
    RX_GET_TOKEN_PERMISSION: 'RX_GET_TOKEN_PERMISSION',
    RX_GET_TOKEN_PERMISSION_PENDING: 'RX_GET_TOKEN_PERMISSION_PENDING',
    RX_GET_TOKEN_PERMISSION_DONE: 'RX_GET_TOKEN_PERMISSION_DONE'

};

export const LANGUAGES = {
    CNTRL_GET_TRANSLATIONS: 'CNTRL_GET_TRANSLATIONS',
    RX_GET_TRANSLATIONS: 'RX_GET_TRANSLATIONS',
    RX_GET_TRANSLATIONS_PENDING: 'RX_GET_TRANSLATIONS_PENDING',
    RX_GET_TRANSLATIONS_DONE: 'RX_GET_TRANSLATIONS_DONE',

    CNTRL_GET_LANGUAGES: 'CNTRL_GET_LANGUAGES',
    RX_GET_LANGUAGES: 'RX_GET_LANGUAGES',
    RX_GET_LANGUAGES_PENDING: 'RX_GET_LANGUAGES_PENDING',
    RX_GET_LANGUAGES_DONE: 'RX_GET_LANGUAGES_DONE'

};

export const USERS = {
    CNTRL_GET_USERS: 'CNTRL_GET_USERS',
    RX_GET_USERS: 'RX_GET_USERS',
    RX_GET_USERS_PENDING: 'RX_GET_USERS_PENDING',
    RX_GET_USERS_DONE: 'RX_GET_USERS_DONE'
};

export const MAINTENANCES = {
    CNTRL_GET_MAINTENANCES: 'CNTRL_GET_MAINTENANCES',
    RX_GET_MAINTENANCES: 'RX_GET_MAINTENANCES',
    RX_GET_MAINTENANCES_PENDING: 'RX_GET_MAINTENANCES_PENDING',
    RX_GET_MAINTENANCES_DONE: 'RX_GET_MAINTENANCES_DONE',

    // !ADD MAINTENANCES
    CNTRL_ADD_MAINTENANCES: 'CNTRL_ADD_MAINTENANCES',
    RX_ADD_MAINTENANCES: 'RX_ADD_MAINTENANCES',
    RX_ADD_MAINTENANCES_PENDING: 'RX_ADD_MAINTENANCES_PENDING',
    RX_ADD_MAINTENANCES_DONE: 'RX_ADD_MAINTENANCES_DONE',

    // CANCEL MAINTENANCES
    CNTRL_CANCEL_MAINTENANCES: 'CNTRL_CANCEL_MAINTENANCES',
    RX_CANCEL_MAINTENANCES: 'RX_CANCEL_MAINTENANCES',
    RX_CANCEL_MAINTENANCES_PENDING: 'RX_CANCEL_MAINTENANCES_PENDING',
    RX_CANCEL_MAINTENANCES_DONE: 'RX_CANCEL_MAINTENANCES_DONE',

    //EDIT
    CNTRL_EDIT_END_DATE: 'CNTRL_EDIT_END_DATE',
    RX_EDIT_END_DATE: 'RX_EDIT_END_DATE',
    RX_EDIT_END_DATE_PENDING: 'RX_EDIT_END_DATE_PENDING',
    RX_EDIT_END_DATE_DONE: 'RX_EDIT_END_DATE_DONE',

    RX_IS_FINISHED_MAINTENANCES: 'RX_IS_FINISHED_MAINTENANCES',
};


export const SIGNAL_R_TYPES = {
    CNTRL_SIGNAL_R_CONNECT: 'CNTRL_SIGNAL_R_CONNECT',

    RX_OPEN_NOTIFY: 'RX_OPEN_NOTIFY',

};

export const USER_ROLE_TYPES = {
    RX_SET_USER_ROLE: 'RX_SET_USER_ROLE'
};

export const BET_TYPES = {
    CNTRL_GET_BET_TYPES: 'CNTRL_GET_BET_TYPES',
    RX_GET_BET_TYPES: 'RX_GET_BET_TYPES',
    RX_GET_BET_TYPES_PENDING: 'RX_GET_BET_TYPES_PENDING',
    RX_GET_BET_TYPES_DONE: 'RX_GET_BET_TYPES_DONE',
};

export const TOURNAMENT_TYPES = {
    CNTRL_GET_TOURNAMENTS: 'CNTRL_GET_TOURNAMENTS',
    RX_GET_TOURNAMENTS: 'RX_GET_TOURNAMENTS',
    RX_GET_TOURNAMENTS_PENDING: 'RX_GET_TOURNAMENTS_PENDING',
    RX_GET_TOURNAMENTS_DONE: 'RX_GET_TOURNAMENTS_DONE',

    CNTRL_GET_TOURNAMENT: 'CNTRL_GET_TOURNAMENT',
    RX_GET_TOURNAMENT: 'RX_GET_TOURNAMENT',
    RX_GET_TOURNAMENT_PENDING: 'RX_GET_TOURNAMENT_PENDING',
    RX_GET_TOURNAMENT_DONE: 'RX_GET_TOURNAMENT_DONE',

    CNTRL_ADD_TOURNAMENT: 'CNTRL_ADD_TOURNAMENT',

    CNTRL_UPDATE_TOURNAMENT: 'CNTRL_UPDATE_TOURNAMENT',

    CNTRL_CANCEL_TOURNAMENT: 'CNTRL_CANCEL_TOURNAMENT',

    CNTRL_GET_TOURNAMENT_PLAYERS: 'CNTRL_GET_TOURNAMENT_PLAYERS',
    RX_GET_TOURNAMENT_PLAYERS: 'RX_GET_TOURNAMENT_PLAYERS',
    RX_GET_TOURNAMENT_PLAYERS_PENDING: 'RX_GET_TOURNAMENT_PLAYERS_PENDING',
    RX_GET_TOURNAMENT_PLAYERS_DONE: 'RX_GET_TOURNAMENT_PLAYERS_DONE',

    CNTRL_GET_PARTNERS_TOURNAMENT: 'CNTRL_GET_PARTNERS_TOURNAMENT',
    RX_GET_PARTNERS_TOURNAMENT: 'RX_GET_PARTNERS_TOURNAMENT',
    RX_GET_PARTNERS_TOURNAMENT_PENDING: 'RX_GET_PARTNERS_TOURNAMENT_PENDING',
    RX_GET_PARTNERS_TOURNAMENT_DONE: 'RX_GET_PARTNERS_TOURNAMENT_DONE',
};

export const USER_TOURNAMENT_TYPES = {
    CNTRL_GET_USER_TOURNAMENTS_REPORT: 'CNTRL_GET_USER_TOURNAMENTS_REPORT',
    RX_GET_USER_TOURNAMENTS_REPORT: 'RX_GET_USER_TOURNAMENTS_REPORT',
    RX_GET_USER_TOURNAMENTS_REPORT_PENDING: 'RX_GET_USER_TOURNAMENTS_REPORT_PENDING',
    RX_GET_USER_TOURNAMENTS_REPORT_DONE: 'RX_GET_USER_TOURNAMENTS_REPORT_DONE',

    CNTRL_GET_USER_TOURNAMENTS_RANGE_REPORT: 'CNTRL_GET_USER_TOURNAMENTS_RANGE_REPORT',
    RX_GET_USER_TOURNAMENTS_RANGE_REPORT: 'RX_GET_USER_TOURNAMENTS_RANGE_REPORT',
    RX_GET_USER_TOURNAMENTS_RANGE_REPORT_PENDING: 'RX_GET_USER_TOURNAMENTS_RANGE_REPORT_PENDING',
    RX_GET_USER_TOURNAMENTS_RANGE_REPORT_DONE: 'RX_GET_USER_TOURNAMENTS_RANGE_REPORT_DONE',
};

export const FREE_BET_TYPES = {
    CNTRL_GET_FREE_BETS: 'CNTRL_GET_FREE_BETS',
    RX_GET_FREE_BETS: 'RX_GET_FREE_BETS',
    RX_GET_FREE_BETS_PENDING: 'RX_GET_FREE_BETS_PENDING',
    RX_GET_FREE_BETS_DONE: 'RX_GET_FREE_BETS_DONE',

    CNTRL_GET_FREE_BET: 'CNTRL_GET_FREE_BET',
    RX_GET_FREE_BET: 'RX_GET_FREE_BET',
    RX_GET_FREE_BET_PENDING: 'RX_GET_FREE_BET_PENDING',
    RX_GET_FREE_BET_DONE: 'RX_GET_FREE_BET_DONE',

    CNTRL_GET_FREE_BET_REPORT: 'CNTRL_GET_FREE_BET_REPORT',
    RX_GET_FREE_BET_REPORT: 'RX_GET_FREE_BET_REPORT',
    RX_GET_FREE_BET_REPORT_PENDING: 'RX_GET_FREE_BET_REPORT_PENDING',
    RX_GET_FREE_BET_REPORT_DONE: 'RX_GET_FREE_BET_REPORT_DONE',

    CNTRL_ADD_FREE_BET: 'CNTRL_ADD_FREE_BET',

    CNTRL_EDIT_FREE_BET: 'CNTRL_EDIT_FREE_BET',

    CNTRL_CANCEL_FREE_BET: 'CNTRL_CANCEL_FREE_BET',
};
