import {REPORT_TYPES} from "../../core/constants/actionTypes";

export const cntrlGetFinancialReport = payload => ({
    type : REPORT_TYPES.CNTRL_GET_FINANCIAL_REPORT,
    payload
});
export const rxGetFinancialReportPending = payload => ({
    type : REPORT_TYPES.RX_GET_FINANCIAL_REPORT_PENDING,
    payload
});
export const rxGetFinancialReportDone = payload => ({
    type : REPORT_TYPES.RX_GET_FINANCIAL_REPORT_DONE,
    payload
});
export const rxGetFinancialReport = payload => ({
    type : REPORT_TYPES.RX_GET_FINANCIAL_REPORT,
    payload
});

export const cntrlGetBetReport = payload => ({
    type : REPORT_TYPES.CNTRL_GET_BET_REPORT,
    payload
});

export const rxGetBetReport = payload => ({
    type : REPORT_TYPES.RX_GET_BET_REPORT,
    payload
});

export const rxGetBetReportPending = payload => ({
    type : REPORT_TYPES.RX_GET_BET_REPORT_PENDING,
    payload
});
export const rxGetBetReportDone = payload => ({
    type : REPORT_TYPES.RX_GET_BET_REPORT_DONE,
    payload
});

export const cntrlGetUserReport = payload => ({
    type : REPORT_TYPES.CNTRL_GET_USER_REPORT,
    payload
});

export const rxGetUserReport = payload => ({
   type : REPORT_TYPES.CNTRL_GET_USER_REPORT,
   payload
});

export const rxGetUserReportPending = payload => ({
   type : REPORT_TYPES.RX_GET_USER_REPORT_PENDING,
   payload
});

export const rxGetUserReportDone = payload => ({
   type : REPORT_TYPES.RX_GET_USER_REPORT_DONE,
   payload
});

export const cntrlGetContestReport = payload => ({
   type : REPORT_TYPES.CNTRL_GET_CONTEST_REPORT,
    payload
});

export const rxGetContestReport = payload => ({
   type : REPORT_TYPES.RX_GET_CONTEST_REPORT,
    payload
});

export const rxGetContestReportPending = payload =>({
    type : REPORT_TYPES.RX_GET_CONTEST_REPORT_PENDING,
    payload
});

export const rxGetContestReportDone = payload => ({
   type : REPORT_TYPES.RX_GET_CONTEST_REPORT_DONE,
    payload
});

export const cntrlGetDashboardReport = payload => ({
   type : REPORT_TYPES.CNTRL_GET_DASHBOARD_REPORT,
    payload
});

export const rxGetDashboardReport = payload => ({
    type : REPORT_TYPES.RX_GET_DASHBOARD_REPORT,
    payload
});


export const rxGetDashboardReportPending = payload => ({
    type : REPORT_TYPES.RX_GET_DASHBOARD_REPORT_PENDING,
    payload
});

export const rxGetDashboardReportDone = payload => ({
    type : REPORT_TYPES.RX_GET_DASHBOARD_REPORT_DONE,
    payload
});

export const cntrlGetTopBets = payload => ({
   type : REPORT_TYPES.CNTRL_GET_TOP_BETS,
    payload
});

export const cntrlGetMarketReport = payload => ({
   type : REPORT_TYPES.CNTRL_GET_MARKET_REPORT,
    payload
});

export const rxGetMarketReport = payload => ({
   type : REPORT_TYPES.RX_GET_MARKET_REPORT,
   payload
});

export const rxGetMarketReportPending = payload => ({
   type : REPORT_TYPES.RX_GET_MARKET_REPORT_PENDING,
   payload
});

export const rxGetMarketReportDone = payload => ({
    type : REPORT_TYPES.RX_GET_MARKET_REPORT_DONE,
    payload
});

export const rxGetTopBets = payload => ({
    type : REPORT_TYPES.RX_GET_TOP_BETS,
    payload
});

export const rxGetTopBetsPending = payload => ({
   type : REPORT_TYPES.RX_GET_TOP_BETS_PENDING,
    payload
});


export const rxGetTopBetsDone = payload => ({
   type : REPORT_TYPES.RX_GET_TOP_BETS_DONE    ,
    payload
});

export const cntrlGetTopWinBets = payload => ({
    type : REPORT_TYPES.CNTRL_GET_TOP_WIN_BETS,
    payload
});

export const rxGetTopWinBets = payload => ({
    type : REPORT_TYPES.RX_GET_TOP_WIN_BETS,
    payload
});

export const rxGetTopWinBetsPending = payload => ({
    type : REPORT_TYPES.RX_GET_TOP_WIN_BETS_PENDING,
    payload
});

export const rxGetTopWinBetsDone = payload => ({
    type : REPORT_TYPES.RX_GET_TOP_WIN_BETS_DONE,
    payload
});

export const cntrlGetLoseBets = payload => ({
   type : REPORT_TYPES.CNTRL_GET_LOSE_BET_REPORT,
   payload
});

export const rxGetLoseBetReport = payload => ({
   type : REPORT_TYPES.RX_GET_LOSE_BET_REPORT,
   payload
});

export const rxGetLoseBetReportPending = payload => ({
   type : REPORT_TYPES.RX_GET_LOSE_BET_REPORT_PENDING,
   payload
});

export const rxGetLoseBetReportDone = payload => ({
   type : REPORT_TYPES.RX_GET_LOSE_BET_REPORT_DONE,
   payload
});
