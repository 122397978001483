import { lazy } from 'react'
import { translateText } from '../view/components/translation';


const Dashboard = lazy(() => import('../view/pages/dashboard'));
// const Partners = lazy(()=> import('../view/pages/partners'));  todo save or delete
const PartnersSettings = lazy(() => import('../view/pages/partners/partnersSettings'));
const PartnerConfig = lazy(() => import('../view/pages/partners/partnersSettings/partnerConfig'));
const PartnersLimits = lazy(() => import('../view/pages/partners/partnersLimits'));
const FinancialReport = lazy(() => import('../view/pages/partners/financialReport'));

const ContestTypes = lazy(() => import('../view/pages/partners/contestTypes'));

const BetReports = lazy(() => import('../view/pages/reports/bet-reports'));
const UserReport = lazy(() => import('../view/pages/reports/userReport'));
const ContestReport = lazy(() => import('../view/pages/reports/contestReport'));
const MarketReport = lazy(() => import('../view/pages/reports/market-report'));
const HiloMaster = lazy(() => import('../view/pages/bonus/hiloMaster'));
const FreeBet = lazy(() => import('../view/pages/bonus/freeBet'));
const TournamentUserReport = lazy(() => import('../view/pages/reports/tournamentUserReport'));
const FreeBetUserReport = lazy(() => import('../view/pages/reports/freeBetUserReport'));


const rootRoutes = [
  {
    path: '/dashboard',
    name: translateText('DASHBOARD'),
    component: Dashboard
  },
  {
    path: '/reports',
    name: translateText('REPORTS'),
    component: null,
    innerRoutes: [
      {
        path: '/bet-reports',
        name: translateText('BET_REPORT'),
        component: BetReports,
      },
      {
        path: '/user-report',
        name: translateText('USER_REPORT'),
        component: UserReport
      },
      {
        path: '/contest-report',
        name: translateText('CONTEST_REPORT'),
        component: ContestReport,
      },
      {
        path: '/market-report',
        name: translateText('MARKET_REPORT'),
        component: MarketReport,
      },
      {
        path: '/bonus-reports/free-bet',
        name: translateText('FREE_BET'),
        component: FreeBetUserReport
      },
      {
        path: '/bonus-reports/master',
        name: translateText('MASTER'),
        component: TournamentUserReport,
      },
    ]
    },
    {
        path: '/partners',
        name: translateText('PARTNERS'),
        component: null,
        innerRoutes: [
            {
                path: '/partners-settings',
                name: translateText('PARTNERS_SETTINGS'),
                component: PartnersSettings,
            },
            {
                path: '/settings',
                name: translateText('PARTNERS-SETTINGS'),
                component: PartnerConfig,
            },
            //todo delete PartnersLimits
            {
                path: '/partners-markets',
                name: translateText('PARTNERS_MARKETS'),
                component: PartnersLimits
            },
            {
                path: '/financial-report',
                name: translateText('FINANCIAL_REPORT'),
                component: FinancialReport,
            },
            {
                path: '/contest_types',
                name: translateText('CONTEST_TYPES'),
                component: ContestTypes,
            }
        ]
    },
    {
        path: '/bonus',
        name: translateText('BONUS'),
        component: null,
        innerRoutes: [
            {
                path: '/hilo_master',
                name: translateText('HILO_MASTER'),
                component: HiloMaster
            },
            {
                path: '/master_list',
                name: translateText('MASTER_LIST'),
                component: HiloMaster
            },
            {
                path: '/freebet',
                name: translateText('FREEBET'),
                component: FreeBet
            },
        ]
    }
];

export const defaultPath = '/dashboard';
export default rootRoutes;
