import HiLoBackofficeApiHandler from '../HiLoBackofficeApiHandler';


const END_POINT = {
    prefix: 'Tournaments',
    tournaments: 'tournaments',
    status: 'status',
    players: 'players',
    partnersTournament: 'partnersTournament'
};

class TournamentApiHandler extends HiLoBackofficeApiHandler {

    getTournaments(query) {
        return super.get(END_POINT.tournaments, query)
    }

    getTournament(query) {
        return super.get('', query)
    }

    addTournament(data) {
        return super.post('', data)
    }

    updateTournament(data) {
        return super.put('', data)
    }

    cancelTournament(data) {
        return super.put(END_POINT.status, '', data)
    }

    getTournamentPlayers(query) {
        return super.get(END_POINT.players, query)
    }

    getPartnersTournament(query) {
        return super.get(END_POINT.partnersTournament, query)
    }

}

const tournamentApiHandler = new TournamentApiHandler(END_POINT.prefix);
export default tournamentApiHandler;
