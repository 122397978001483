import React, {Suspense, useEffect, useState} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import store from '../state-management/store/index';
import { Provider } from 'react-redux';
import App from './app/index';
import LoginPage from './pages/login/index';
import rootRoutes from '../routing/router';
import Splash from './containers/splash/index'
import defineGuards from '../routing/defineGuards/defineGuards';
import IsAuthLayer from '../routing/Layers/isAuthLayer';
import IsNotAuthLayer from '../routing/Layers/isNotAuthLayer';
import translationService from "../services/services/TranslationService";
import {TranslationContext} from "../contexts";


const rootPaths = [];

rootRoutes.concat({ path: '/' }).forEach(route => {
  if (route.innerRoutes && route.innerRoutes.length) {

    route.innerRoutes.forEach(inner => {
      rootPaths.push(`${route.path}${inner.path}`);
    })
  } else {
    rootPaths.push(route.path)
  }
});

const AppWrapper = (props) => {
  const [translations , setTranslations ] = useState({});


  useEffect(()=> {
    const subscribeId = translationService.subscribe(setTranslations);
    return () => {
      translationService.unsubscribe(subscribeId)
    }
  } , []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <TranslationContext.Provider value={translations}>
          <div id={'holder-portal-container'}/>
          <Splash>
            <Switch>
              <Route exact path='/login' render={props => defineGuards([IsNotAuthLayer], LoginPage, props)}/>
              <Route path={'/'} render={props => defineGuards([IsAuthLayer], App, props)}/>
              <Route path={'*'} render={_ => <div>No Page</div>}/>
            </Switch>
          </Splash>
        </TranslationContext.Provider>
      </BrowserRouter>
    </Provider>
  )
};


export default AppWrapper;
