import {MAINTENANCES} from "../../core/constants/actionTypes";

// get
export const cntrlGetMaintenances = payload => ({
    type: MAINTENANCES.CNTRL_GET_MAINTENANCES,
    payload
});
export const rxGetMaintenances = payload => ({
    type: MAINTENANCES.RX_GET_MAINTENANCES,
    payload
});
export const rxGetMaintenancesPending = payload => ({
    type: MAINTENANCES.RX_GET_MAINTENANCES_PENDING,
    payload
});
export const rxGetMaintenancesDone = payload => ({
    type: MAINTENANCES.RX_GET_MAINTENANCES_DONE,
    payload
});


// add
export const cntrlAddMaintenances = payload => ({
    type: MAINTENANCES.CNTRL_ADD_MAINTENANCES,
    payload,
});
export const rxAddMaintenances = payload => ({
    type: MAINTENANCES.RX_ADD_MAINTENANCES,
    payload,
});
export const rxAddMaintenancesPending = payload => ({
    type: MAINTENANCES.RX_ADD_MAINTENANCES_PENDING,
    payload,
});
export const rxAddMaintenancesDone = payload => ({
    type: MAINTENANCES.RX_ADD_MAINTENANCES_DONE,
    payload,
});


// cancel
export const cntrlCancelMaintenances = payload => ({
    type: MAINTENANCES.CNTRL_CANCEL_MAINTENANCES,
    payload,
});
export const rxCancelMaintenances = payload => ({
    type: MAINTENANCES.RX_CANCEL_MAINTENANCES,
    payload,
});
export const rxCancelMaintenancesPending = payload => ({
    type: MAINTENANCES.RX_CANCEL_MAINTENANCES_PENDING,
    payload,
});
export const rxCancelMaintenancesDone = payload => ({
    type: MAINTENANCES.RX_CANCEL_MAINTENANCES_DONE,
    payload,
});


//edit
export const cntrlEditEndDate= payload => ({
    type: MAINTENANCES.CNTRL_EDIT_END_DATE,
    payload,
});
export const rxEditEndDate= payload => ({
    type: MAINTENANCES.RX_CANCEL_MAINTENANCES,
    payload,
});
export const rxEditEndDatePending = payload => ({
    type: MAINTENANCES.RX_CANCEL_MAINTENANCES_PENDING,
    payload,
});
export const rxEditEndDateDone = payload => ({
    type: MAINTENANCES.RX_CANCEL_MAINTENANCES_DONE,
    payload,
});
//
export const rxSetFinishedMaintenance = payload => ({
    type: MAINTENANCES.RX_IS_FINISHED_MAINTENANCES,
    payload,
});