import Controller from "../../core/classes/AppController";
// import { SignalrNotificationActionType } from '../../core/constants/SignalRTypes';
import { cntrlSignalRConnect } from '../../state-management/actions/signalRActions';

import webSocketService from '../../services/services/WebSocketService';

export const SignalRConstants = {
    Notify: 'Notify',
    actionType: 'actionType',
    arguments:'arguments',
    MaintenanceTime: 'MaintenanceTime',
    MaintenanceStatus:"MaintenanceStatus",

};

class SignalRController extends Controller {
    constructor(signalROperations) {
        super();
        this.signalROperations = signalROperations;
    }


    connect = (store, action) => {
        try {
            this.connection = this.signalROperations.createConnection();
            this.connection
                .start()
                .then(() => {
                    this.connection &&
                    this.connection.onclose(() => {
                        store.dispatch(cntrlSignalRConnect());
                    });
                })
                .catch(err => {
                    console.error(err);
                    this.connection = null;
                    store.dispatch(cntrlSignalRConnect());
                });
            this.connection.on(SignalRConstants.MaintenanceStatus, data => {

                webSocketService.setState(SignalRConstants.arguments);
                if (!data.hasOwnProperty('startDate')) { // TODO

                    this.signalROperations.maintenanceFinished(store, data);
                }
            });
        } catch (e) {
            console.error(e);
        }
    };
}

export default SignalRController;
