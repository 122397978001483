import * as ApiHandlers from '../services/api';
import * as Controllers from '../controllers';
import * as Operations from '../services/operations';
import signalROperations from "../services/operations/signalROperation";


//operations
const tokenOperation = new Operations.TokenOperation(ApiHandlers.tokenApiHandler);
const reportsOperation = new Operations.ReportsOperation(ApiHandlers.reportsApiHandler);
const languagesOperation = new Operations.LanguagesOperation(ApiHandlers.languagesApiHandler);
const partnersOperation = new Operations.PartnersOperation(ApiHandlers.partnersApiHandler);
const usersOperation = new Operations.UsersOperation(ApiHandlers.usersApiHandler);
const contestTypesOperation = new Operations.ContestTypesOperation(ApiHandlers.contestTypesApiHandler);
const maintenancesOperation = new Operations.MaintenancesOperations(ApiHandlers.maintenancesApiHandler);
const betTypesOperation = new Operations.BetTypesOperation(ApiHandlers.betTypesApiHandler);
const tournamentOperations = new Operations.TournamentOperations(ApiHandlers.tournamentApiHandler);
const userTournamentsOperations = new Operations.UserTournamentsOperations(ApiHandlers.userTournamentsApiHandler);
const freeBetOperations = new Operations.FreeBetOperations(ApiHandlers.freeBetApiHandler);


//controllers
const appController = new Controllers.AppController();
const reportsController = new Controllers.ReportsController(reportsOperation);
const tokenController = new Controllers.TokenController(tokenOperation);
const languagesController = new Controllers.LanguagesController(languagesOperation);
const partnersController = new Controllers.PartnersController(partnersOperation);
const usersController = new Controllers.UserController(usersOperation);
const contestTypesController = new Controllers.ContestTypesController(contestTypesOperation);
const maintenancesController = new Controllers.MaintenancesController(maintenancesOperation);
const signalRController = new Controllers.SignalRController(signalROperations);
const betTypesController = new Controllers.BetTypesController(betTypesOperation);
const tournamentController = new Controllers.TournamentController(tournamentOperations);
const userTournamentsController = new Controllers.UserTournamentController(userTournamentsOperations);
const freeBetController = new Controllers.FreeBetController(freeBetOperations);


export {
  appController,
  tokenController,
  languagesController,
  reportsController,
  partnersController,
  usersController,
  contestTypesController,
  maintenancesController,
  signalRController,
  betTypesController,
  tournamentController,
  userTournamentsController,
  freeBetController,
};
