import React from 'react';
import {connect} from 'react-redux';
import store from '../../../state-management/store/index'


const Translations = ({translationKey, translations, ...props}) => translations.isLoading ? '...' : <>{translations.data[translationKey] || translationKey}</>;

const mapStateToProps = state => ({
  translations : state.translations
});
const TranslationsText = connect(mapStateToProps)(Translations);

export const translateText = (key,  isValue = false) => isValue ? (store.getState().translations && store.getState().translations.data[key] ?  store.getState().translations.data[key] : key) : <TranslationsText translationKey={key}/>