import Operations from "../../../core/classes/AppOperation";
import responseErrorCheck from "../../../core/helpers/responseErrorChecker";
import store from "../../../state-management/store";
import { cntrlLogOut } from "../../../state-management/actions/tokenActions";

class UsersOperation extends Operations{
    constructor(usersApiHandler) {
        super();
        this.usersApiHandler = usersApiHandler;
    }

    async getUser(){
        const response = await this.usersApiHandler.getUser();
        if (response !== null) {
            return responseErrorCheck(response);
        } else {
            store.dispatch(cntrlLogOut());
            return null;
        }
    }
}

export default UsersOperation;
