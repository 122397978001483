import alertify from 'alertifyjs';
import ErrorHandler from '../../core/helpers/ErrorHandler';
import {translateText} from '../../view/components/translation/index';

const handleException = response => {

  if (response instanceof Error) {
    if (response.hasOwnProperty(ErrorHandler.errorConstants.message)) {
      ErrorHandler.getErrorByStatus(translateText(response.message, true)) && alertify.error(ErrorHandler.getErrorByStatus(translateText(response.message, true)).toString());
      return {
        error: response.message,
      };
    }
    if (response.hasOwnProperty(ErrorHandler.errorConstants.error
      && response.error.hasOwnProperty(ErrorHandler.errorConstants.message))) {
      alertify.error(translateText(response.error.message, true));
      return {
        error: response.error.message
      };
    }
  }
  return response;
};

const handleAtomicExceptions = err => {
  console.error(err);
  // alertify.error(err.message);
};

/**
 * This middleware declares if action for controller or for reducer
 * @param configs
 */

const controllerMiddleware = configs => state => next => action => {
  if ([action.tyconfigspe] && typeof configs[action.type] === 'function') {
    try {
      let result = configs[action.type](state, action);
      if (result instanceof Promise) {
        result = result.then(handleException);
      }
      return result;
    } catch ( err ) {
      handleAtomicExceptions(err);
    }
  } else {
    return next(action);
  }
};

export default controllerMiddleware;