import Controller from '../../core/classes/AppController';
import LocalStorageHelper from '../../core/helpers/localStorageHelper';
import { ACCESS } from '../../core/constants/util';
import ApiHandler from '../../core/classes/ApiHandler';
import {cntrlGetLanguages, cntrlGetTranslations} from "../../state-management/actions/languagesActions";
import {cntrlGetUser} from "../../state-management/actions/usersActions";
import {cntrlGetPartnerCurrencies, rxSetPartnerCurrency} from "../../state-management/actions/partnersAction";
import {cntrlRefreshToken, rxSetIsAuth} from "../../state-management/actions/tokenActions";
import {cntrlSignalRConnect} from "../../state-management/actions/signalRActions";

class AppController extends Controller{
  constructor(appOperation){
    super();
    this.appOperation = appOperation;
  }
  init = async (store , action) => {
    store.dispatch(cntrlGetTranslations());
    store.dispatch(cntrlGetLanguages());
    // store.dispatch(cntrlGetPartnerCurrencies());
    store.dispatch(cntrlSignalRConnect())
    const access = LocalStorageHelper.getItem(ACCESS);
    if(access){
      if (access && access.expiresIn > Date.now()){

        ApiHandler.token = access.accessToken;
        // const clientId = Number(JSON.parse(atob(access.accessToken.split('.')[1])).sub);
        await store.dispatch(cntrlGetUser());
        // await store.dispatch(cntrlGetPartnerCurrencies());
        this.currencySetter(store);
        store.dispatch(cntrlRefreshToken());
        return true
      }else {
        // store.dispatch(rxSetIsAuth(false))
        return false
      }

    }else{
      return false
    }
  };

  currencySetter = async (store) => {
    const currencyCode = store.getState().users.user.data.currencyCode;
    store.dispatch(rxSetPartnerCurrency(currencyCode))
  }

}

export default AppController;
