import Operations from "../../../core/classes/AppOperation";
import responseErrorCheck from "../../../core/helpers/responseErrorChecker";
import {Toaster} from "bet-core-ui";

class MaintenancesOperations extends Operations{
    constructor(maintenancesApiHandler){
        super();
        this.maintenancesApiHandler = maintenancesApiHandler;
    }
    async getMaintenances(){
        const response = await this.maintenancesApiHandler.getMaintenances();
        return responseErrorCheck(response);
    }
    async addMaintenances(data){
        const response = await this.maintenancesApiHandler.addMaintenances(data);
        if(response.status){
            Toaster.success({
                title: 'Success',
                message: 'Operation Completed'
            })
        }
        return responseErrorCheck(response);
    };
    async cancelMaintenances(id){
        const response = await this.maintenancesApiHandler.cancelMaintenances(id);
        return responseErrorCheck(response);
    };
    async editEndDate(endDate){
        const response = await this.maintenancesApiHandler.editEndDate(endDate);
        return responseErrorCheck(response);
    };
}

export default MaintenancesOperations;

